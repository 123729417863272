import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, database } from "../../../firebase";
import { ref, push, remove, onValue } from "firebase/database";
import { FaInfoCircle, FaChevronDown, FaChevronUp } from "react-icons/fa";
import "./VisitCategoriesManager.css";

const VisitCategoriesManager = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const categoriesRef = ref(database, `users/${user.uid}/SupportRoom/visitCategories`);
      onValue(categoriesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const categoryList = Object.entries(data).map(([key, value]) => ({
            id: key,
            name: value,
          }));
          setCategories(categoryList);
        } else {
          setCategories([]);
        }
      });
    }
  }, []);

  const addCategory = () => {
    if (newCategory.trim() !== "") {
      const user = auth.currentUser;
      const categoriesRef = ref(database, `users/${user.uid}/SupportRoom/visitCategories`);
      push(categoriesRef, newCategory.trim());
      setNewCategory("");
    }
  };

  const deleteCategory = (categoryId) => {
    const user = auth.currentUser;
    const categoryRef = ref(
      database,
      `users/${user.uid}/SupportRoom/visitCategories/${categoryId}`
    );
    remove(categoryRef);
  };

  const handleBack = () => {
    navigate("/support-room-tracker/dashboard");
  };
  

  return (
    <div className="visit-categories-manager">
      <div className="visit-categories-manager-header">
        <button onClick={handleBack} className="back-button">
          &larr; Back to Dashboard
        </button>
        <h2 className="visit-categories-manager-title">
          Manage Visit Categories
        </h2>
      </div>

      <div className="info-section">
        <button className="info-toggle" onClick={() => setShowInfo(!showInfo)}>
          <FaInfoCircle /> Information about Categories
          {showInfo ? <FaChevronUp /> : <FaChevronDown />}
        </button>
        {showInfo && (
          <div className="info-content">
            <p>
              Visit categories are used to classify the reasons for student
              visits to the support room. These categories help in tracking and
              analyzing patterns in student support needs.
            </p>
            <p>
              Examples of visit categories might include: "Behavioral Support",
              "Academic Assistance", "Emotional Support", etc.
            </p>
            <p>
              Note: These categories are different from student categories,
              which are used to classify students themselves rather than their
              visits.
            </p>
          </div>
        )}
      </div>

      <div className="add-category-form">
        <input
          type="text"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
          placeholder="New Visit Category"
          className="category-input"
        />
        <button onClick={addCategory} className="add-category-button">
          Add Category
        </button>
      </div>

      <ul className="category-list">
        {categories.map((category) => (
          <li key={category.id} className="category-item">
            <span>{category.name}</span>
            <button
              onClick={() => deleteCategory(category.id)}
              className="delete-category-button"
            >
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default VisitCategoriesManager;
