import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, database } from "../../../firebase";
import { ref, push, onValue } from "firebase/database";
import { FaPlus, FaSearch, FaArrowLeft, FaMinus, FaTimes } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./VisitForm.css";

const VisitForm = () => {
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);
  const [visitCategories, setVisitCategories] = useState([]);
  const [strategies, setStrategies] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([""]);
  const [selectedStrategies, setSelectedStrategies] = useState([""]);
  const [selectedRoom, setSelectedRoom] = useState("");
  const [notes, setNotes] = useState("");
  const [addingNewStudent, setAddingNewStudent] = useState(false);
  const [newCategoryInputs, setNewCategoryInputs] = useState({});
  const [newStrategyInputs, setNewStrategyInputs] = useState({});
  const [newStudent, setNewStudent] = useState({
    firstName: "",
    lastName: "",
    age: "",
    grade: "",
    teacher: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [visitDateTime, setVisitDateTime] = useState(new Date());
  const [duplicateCategories, setDuplicateCategories] = useState({});
  const [duplicateStrategies, setDuplicateStrategies] = useState({});

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const studentsRef = ref(database, `users/${user.uid}/SupportRoom/students`);
      const categoriesRef = ref(database, `users/${user.uid}/SupportRoom/visitCategories`);
      const roomsRef = ref(database, `users/${user.uid}/SupportRoom/rooms`);
      const strategiesRef = ref(database, `users/${user.uid}/SupportRoom/strategies`);

      onValue(studentsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const studentList = Object.entries(data).map(([key, value]) => ({
            id: key,
            ...value,
          }));
          setStudents(studentList);
        }
      });

      onValue(categoriesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const categoryList = Object.entries(data).map(([key, value]) => ({
            id: key,
            name: value,
          }));
          setVisitCategories(categoryList);
        }
      });

      onValue(roomsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const roomList = Object.entries(data).map(([key, value]) => ({
            id: key,
            name: value,
          }));
          setRooms(roomList);
        }
      });

      onValue(strategiesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const strategyList = Object.entries(data).map(([key, value]) => ({
            id: key,
            name: value,
          }));
          setStrategies(strategyList);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const filtered = students.filter((student) =>
        `${student.firstName} ${student.lastName}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
      setFilteredStudents(filtered);
    } else {
      setFilteredStudents([]);
    }
  }, [searchTerm, students]);

  const normalizeString = (str) => {
    return str.replace(/\s+/g, '').toLowerCase(); // Remove all spaces and convert to lowercase
  };

  const handleStudentSelect = (student) => {
    setSelectedStudent(student);
    setSearchTerm("");
    setFilteredStudents([]);
    setAddingNewStudent(false);
  };

  const handleAddNewStudent = () => {
    setAddingNewStudent(true);
    setSelectedStudent(null);
    setSearchTerm("");
    setFilteredStudents([]);
  };

  const handleResetSelection = () => {
    setSelectedStudent(null);
    setSearchTerm("");
    setFilteredStudents([]);
    setAddingNewStudent(false);
    setNewStudent({ firstName: "", lastName: "", age: "", grade: "", teacher: "" });
  };

  const handleCategoryChange = (index, value) => {
    const updatedCategories = [...selectedCategories];
    updatedCategories[index] = value;
    setSelectedCategories(updatedCategories);

    if (value === "new") {
      setNewCategoryInputs(prev => ({ ...prev, [index]: "" }));
    } else {
      setNewCategoryInputs(prev => {
        const updated = { ...prev };
        delete updated[index];
        return updated;
      });
      setDuplicateCategories(prev => {
        const updated = { ...prev };
        delete updated[index];
        return updated;
      });
    }
  };

  const handleStrategyChange = (index, value) => {
    const updatedStrategies = [...selectedStrategies];
    updatedStrategies[index] = value;
    setSelectedStrategies(updatedStrategies);

    if (value === "new") {
      setNewStrategyInputs(prev => ({ ...prev, [index]: "" }));
    } else {
      setNewStrategyInputs(prev => {
        const updated = { ...prev };
        delete updated[index];
        return updated;
      });
      setDuplicateStrategies(prev => {
        const updated = { ...prev };
        delete updated[index];
        return updated;
      });
    }
  };

  const handleNewCategoryInputChange = (index, value) => {
    setNewCategoryInputs(prev => ({ ...prev, [index]: value }));
    
    const isDuplicate = visitCategories.some(
      category => normalizeString(category.name) === normalizeString(value)
    ) || Object.values(newCategoryInputs).some(
      (input, i) => i !== index && normalizeString(input) === normalizeString(value)
    );
    
    setDuplicateCategories(prev => ({
      ...prev,
      [index]: isDuplicate,
    }));
  };

  const handleNewStrategyInputChange = (index, value) => {
    setNewStrategyInputs(prev => ({ ...prev, [index]: value }));

    const isDuplicate = strategies.some(
      strategy => normalizeString(strategy.name) === normalizeString(value)
    ) || Object.values(newStrategyInputs).some(
      (input, i) => i !== index && normalizeString(input) === normalizeString(value)
    );

    setDuplicateStrategies(prev => ({
      ...prev,
      [index]: isDuplicate,
    }));
  };

  const addCategoryField = () => {
    setSelectedCategories([...selectedCategories, ""]);
  };

  const addStrategyField = () => {
    setSelectedStrategies([...selectedStrategies, ""]);
  };

  const removeCategoryField = (index) => {
    const updatedCategories = selectedCategories.filter((_, i) => i !== index);
    setSelectedCategories(updatedCategories);
    setNewCategoryInputs(prev => {
      const updated = { ...prev };
      delete updated[index];
      return updated;
    });
    setDuplicateCategories(prev => {
      const updated = { ...prev };
      delete updated[index];
      return updated;
    });
  };

  const removeStrategyField = (index) => {
    const updatedStrategies = selectedStrategies.filter((_, i) => i !== index);
    setSelectedStrategies(updatedStrategies);
    setNewStrategyInputs(prev => {
      const updated = { ...prev };
      delete updated[index];
      return updated;
    });
    setDuplicateStrategies(prev => {
      const updated = { ...prev };
      delete updated[index];
      return updated;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const user = auth.currentUser;
      const visitsRef = ref(database, `users/${user.uid}/SupportRoom/visits`);

      let studentId = selectedStudent ? selectedStudent.id : null;
      const newCategories = [];
      const newStrategies = [];

      try {
        if (addingNewStudent) {
          const studentsRef = ref(database, `users/${user.uid}/SupportRoom/students`);
          const newStudentRef = await push(studentsRef, newStudent);
          studentId = newStudentRef.key;
        }

        // Handle new categories
        for (const [index, categoryId] of selectedCategories.entries()) {
          if (categoryId === "new") {
            const newCategoryName = newCategoryInputs[index];
            if (newCategoryName) {
              const categoriesRef = ref(database, `users/${user.uid}/SupportRoom/visitCategories`);
              const newCategoryRef = await push(categoriesRef, newCategoryName);
              newCategories.push(newCategoryRef.key);
            }
          } else if (categoryId) {
            newCategories.push(categoryId);
          }
        }

        // Handle new strategies
        for (const [index, strategyId] of selectedStrategies.entries()) {
          if (strategyId === "new") {
            const newStrategyName = newStrategyInputs[index];
            if (newStrategyName) {
              const strategiesRef = ref(database, `users/${user.uid}/SupportRoom/strategies`);
              const newStrategyRef = await push(strategiesRef, newStrategyName);
              newStrategies.push(newStrategyRef.key);
            }
          } else if (strategyId) {
            newStrategies.push(strategyId);
          }
        }

        await push(visitsRef, {
          studentId,
          categories: newCategories,
          strategies: newStrategies,
          roomId: selectedRoom,
          notes,
          timestamp: visitDateTime.getTime(),
        });

        navigate("/support-room-tracker/dashboard");
      } catch (error) {
        console.error("Error adding visit:", error);
        alert("Failed to add visit. Please try again.");
      }
    }
  };

  const validateForm = () => {
    if (!selectedStudent && !addingNewStudent) {
      alert("Please select a student or add a new one.");
      return false;
    }
    if (addingNewStudent && (!newStudent.firstName || !newStudent.lastName)) {
      alert("Please provide at least the first and last name for the new student.");
      return false;
    }
    if (selectedCategories.every(cat => !cat) && !Object.values(newCategoryInputs).some(val => val)) {
      alert("Please select at least one category or add a new one.");
      return false;
    }
    if (selectedStrategies.every(strat => !strat) && !Object.values(newStrategyInputs).some(val => val)) {
      alert("Please select at least one strategy or add a new one.");
      return false;
    }
    if (!selectedRoom) {
      alert("Please select a room.");
      return false;
    }
    return true;
  };

  const isSubmitDisabled = Object.values(duplicateCategories).some(Boolean) || 
                           Object.values(duplicateStrategies).some(Boolean);

  return (
    <div className="visit-form">
      <div className="form-header">
        <button onClick={() =>  navigate("/support-room-tracker/dashboard")} className="back-button">
          <FaArrowLeft /> Back to Dashboard
        </button>
        <h2>New Visit</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Student:</label>
          <div className="search-container">
            <input
              type="text"
              placeholder="Search students..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
              disabled={!!selectedStudent || addingNewStudent}
            />
            <FaSearch className="search-icon" />
          </div>
          {filteredStudents.length > 0 && (
            <ul className="student-list">
              {filteredStudents.map((student) => (
                <li
                  key={student.id}
                  onClick={() => handleStudentSelect(student)}
                  className="student-item"
                >
                  {student.firstName} {student.lastName}
                </li>
              ))}
            </ul>
          )}
          {selectedStudent && (
            <div className="selected-student">
              Selected: {selectedStudent.firstName} {selectedStudent.lastName}
              <button onClick={handleResetSelection} className="reset-selection">
                <FaTimes />
              </button>
            </div>
          )}
          {!selectedStudent && !addingNewStudent && (
            <button onClick={handleAddNewStudent} className="add-new-button">
              <FaPlus /> Add New Student
            </button>
          )}
          {addingNewStudent && (
            <div className="new-student-form">
              <input
                type="text"
                placeholder="First Name"
                value={newStudent.firstName}
                onChange={(e) =>
                  setNewStudent({ ...newStudent, firstName: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Last Name"
                value={newStudent.lastName}
                onChange={(e) =>
                  setNewStudent({ ...newStudent, lastName: e.target.value })
                }
              />
              <input
                type="number"
                placeholder="Age"
                value={newStudent.age}
                onChange={(e) =>
                  setNewStudent({ ...newStudent, age: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Grade"
                value={newStudent.grade}
                onChange={(e) =>
                  setNewStudent({ ...newStudent, grade: e.target.value })
                }
              />
              <input
                type="text"
                placeholder="Teacher"
                value={newStudent.teacher}
                onChange={(e) =>
                  setNewStudent({ ...newStudent, teacher: e.target.value })
                }
              />
              <button onClick={handleResetSelection} className="reset-selection">
                <FaTimes />
              </button>
            </div>
          )}
        </div>

        <div className="form-group">
          <label>Date and Time:</label>
          <DatePicker
            selected={visitDateTime}
            onChange={(date) => setVisitDateTime(date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="MMMM d, yyyy h:mm aa"
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label>Room:</label>
          <select
            value={selectedRoom}
            onChange={(e) => setSelectedRoom(e.target.value)}
          >
         <option value="">Select a room</option>
            {rooms.map((room) => (
              <option key={room.id} value={room.id}>
                {room.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>Visit Categories:</label>
          {selectedCategories.map((categoryId, index) => (
            <div key={index} className="category-field">
              <select
                value={categoryId}
                onChange={(e) => handleCategoryChange(index, e.target.value)}
              >
                <option value="">Select a category</option>
                {visitCategories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
                <option value="new">Add new category</option>
              </select>
              {categoryId === "new" && (
                <>
                  <input
                    type="text"
                    value={newCategoryInputs[index] || ""}
                    onChange={(e) => handleNewCategoryInputChange(index, e.target.value)}
                    placeholder="Enter new category name"
                    className="new-item-input"
                  />
                  {duplicateCategories[index] && (
                    <span className="error-message">This category already exists.</span>
                  )}
                </>
              )}
              {index === selectedCategories.length - 1 ? (
                <button
                  type="button"
                  onClick={addCategoryField}
                  className="add-item-field"
                >
                  <FaPlus />
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => removeCategoryField(index)}
                  className="remove-item-field"
                >
                  <FaMinus />
                </button>
              )}
            </div>
          ))}
        </div>

        <div className="form-group">
          <label>Strategies:</label>
          {selectedStrategies.map((strategyId, index) => (
            <div key={index} className="category-field">
              <select
                value={strategyId}
                onChange={(e) => handleStrategyChange(index, e.target.value)}
              >
                <option value="">Select a strategy</option>
                {strategies.map((strategy) => (
                  <option key={strategy.id} value={strategy.id}>
                    {strategy.name}
                  </option>
                ))}
                <option value="new">Add new strategy</option>
              </select>
              {strategyId === "new" && (
                <>
                  <input
                    type="text"
                    value={newStrategyInputs[index] || ""}
                    onChange={(e) => handleNewStrategyInputChange(index, e.target.value)}
                    placeholder="Enter new strategy name"
                    className="new-item-input"
                  />
                  {duplicateStrategies[index] && (
                    <span className="error-message">This strategy already exists.</span>
                  )}
                </>
              )}
              {index === selectedStrategies.length - 1 ? (
                <button
                  type="button"
                  onClick={addStrategyField}
                  className="add-item-field"
                >
                  <FaPlus />
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => removeStrategyField(index)}
                  className="remove-item-field"
                >
                  <FaMinus />
                </button>
              )}
            </div>
          ))}
        </div>

        <div className="form-group">
          <label>Notes:</label>
          <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Enter visit notes (optional)"
          />
        </div>

        <button 
          type="submit" 
          className="submit-visit-button"
          disabled={isSubmitDisabled}
        >
          Submit Visit
        </button>
        {isSubmitDisabled && (
          <p className="error-message">Please resolve duplicate categories or strategies before submitting.</p>
        )}
      </form>
      
    </div>
  );
};

export default VisitForm;
