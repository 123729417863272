// src/components/Dashboard.js
import React, { useState, useEffect } from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { useNavigate, Link } from 'react-router-dom';
import { FaSignOutAlt, FaRobot, FaMicrophone, FaChartBar, FaUser } from 'react-icons/fa';
import './DashboardMain.css';

function Dashboard() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const apps = [
    { name: 'AI Assistants', icon: <FaRobot />, path: '/ai-assistants', description: 'Create and manage AI teaching assistants' },
    { name: 'AI Voice', icon: <FaMicrophone />, path: '/ai-voice', description: 'Convert text to lifelike speech' },
    { name: 'Support Room Tracker', icon: <FaChartBar />, path: '/support-room-tracker', description: 'Track and analyze student support sessions' },
  ];

  // Function to get user's display name or email
  const getUserDisplayName = () => {
    if (user) {
      return user.displayName || user.email.split('@')[0] || 'User';
    }
    return 'User';
  };

  return (
    <div className="dashboard">
      <header className="dashboard-header">
        <div className="header-content">
          <div className="branding">
            <h1 className="company-name">edbotz</h1>
            <h2 className="dashboard-title">Application Hub</h2>
          </div>
          <div className="user-info">
            <span className="welcome-message">Welcome, {getUserDisplayName()}</span>
            <button onClick={handleLogout} className="signout-button">
              <FaSignOutAlt /> Sign Out
            </button>
          </div>
        </div>
      </header>

      <main className="dashboard-main">
        <h2 className="apps-title">Your Applications</h2>
        <div className="apps-grid">
          {apps.map((app, index) => (
            <Link to={app.path} key={index} className="app-card">
              <div className="app-icon">{app.icon}</div>
              <h3 className="app-name">{app.name}</h3>
              <p className="app-description">{app.description}</p>
            </Link>
          ))}
        </div>
      </main>

      <footer className="dashboard-footer">
        <p>&copy; 2024 edbotz.com. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Dashboard;