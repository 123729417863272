import React, { useState, useEffect, useRef } from 'react';
import { BarChart, Bar, LineChart, Line, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { format, getDay } from 'date-fns';
import html2canvas from 'html2canvas';
import Select from 'react-select';
import './Dashboard.css'; 

const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const DAYS_OF_WEEK = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
const SCHOOL_HOURS_START = 7;
const SCHOOL_HOURS_END = 17; // 5 PM

const chartOptions = [
  { value: 'hourOfDay', label: 'Visits by Hour of Day' },
  { value: 'dayOfWeek', label: 'Visits by Day of Week' },
  { value: 'day', label: 'Visits by Day' },
  { value: 'month', label: 'Visits by Month' },
  { value: 'student', label: 'Visits by Student' },
  { value: 'visitCategory', label: 'Visits by Visit Category' },
  { value: 'studentCategory', label: 'Visits by Student Category' },
  { value: 'room', label: 'Visits by Room' }
];

const chartTypeOptions = [
  { value: 'bar', label: 'Bar Chart' },
  { value: 'line', label: 'Line Chart' },
  { value: 'pie', label: 'Pie Chart' }
];

const DashboardCharts = ({ filters, visits, onChartImageChange }) => {
  const { students, rooms, visitCategories, studentCategories, selectedStrategies } = filters;
  const [chartData, setChartData] = useState([]);
  const [selectedChart, setSelectedChart] = useState(chartOptions[0]);
  const [chartType, setChartType] = useState(chartTypeOptions[0]);
  const chartRef = useRef(null);

  useEffect(() => {
    processChartData();
  }, [visits, selectedChart, students, rooms, visitCategories, studentCategories, selectedStrategies, chartType]);

  useEffect(() => {
    if (chartRef.current) {
      const timer = setTimeout(() => {
        html2canvas(chartRef.current).then(canvas => {
          const imageData = canvas.toDataURL('image/png');
          console.log("Chart image data generated:", imageData.substring(0, 50) + "...");
          onChartImageChange(imageData);
        }).catch(error => {
          console.error("Error generating chart image:", error);
        });
      }, 1000); // Wait for 1 second to ensure chart is rendered
      return () => clearTimeout(timer);
    }
  }, [chartData, selectedChart, chartType]);

  const processChartData = () => {
    let processedData = [];
    switch (selectedChart.value) {
      case 'hourOfDay':
        processedData = processHourOfDayData();
        break;
      case 'dayOfWeek':
        processedData = processDayOfWeekData();
        break;
      case 'student':
        processedData = processStudentData();
        break;
      case 'visitCategory':
        processedData = processCategoryData('visit');
        break;
      case 'studentCategory':
        processedData = processCategoryData('student');
        break;
      case 'room':
        processedData = processRoomData();
        break;
      case 'day':
        processedData = processDayData();
        break;
      case 'month':
        processedData = processMonthData();
        break;
      default:
        processedData = [];
    }
    setChartData(processedData);
  };

  const applyStrategiesFilter = (visit) => {
    if (selectedStrategies.length > 0) {
      return visit.strategies && visit.strategies.some(strategy => selectedStrategies.some(fs => fs.value === strategy));
    }
    return true;
  };

  const formatHour = (hour) => {
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 || 12;
    return `${formattedHour}${ampm}`;
  };

  const processHourOfDayData = () => {
    const hourCounts = Array(24).fill(0);
    const hourStudentMap = Array(24).fill().map(() => []);

    visits.forEach(visit => {
      if (applyStrategiesFilter(visit)) {
        const hour = new Date(visit.timestamp).getHours();
        hourCounts[hour]++;
        const student = students[visit.studentId];
        const studentName = student ? `${student.firstName} ${student.lastName}` : 'Unknown Student';
        hourStudentMap[hour].push(studentName);
      }
    });

    let data = hourCounts
      .map((count, hour) => ({
        hour: formatHour(hour),
        count,
        students: hourStudentMap[hour],
        originalHour: hour
      }))
      .slice(SCHOOL_HOURS_START, SCHOOL_HOURS_END + 1);
    
    if (chartType.value === 'pie') {
      data = data.filter(item => item.count > 0);
    }
    return data;
  };

  const processDayOfWeekData = () => {
    const dayCounts = DAYS_OF_WEEK.reduce((acc, day) => ({ ...acc, [day]: 0 }), {});
    const dayStudentMap = DAYS_OF_WEEK.reduce((acc, day) => ({ ...acc, [day]: [] }), {});

    visits.forEach(visit => {
      if (applyStrategiesFilter(visit)) {
        const dayIndex = getDay(new Date(visit.timestamp));
        if (dayIndex > 0 && dayIndex < 6) { // 1 = Monday, 5 = Friday
          const dayName = DAYS_OF_WEEK[dayIndex - 1];
          dayCounts[dayName]++;
          const student = students[visit.studentId];
          const studentName = student ? `${student.firstName} ${student.lastName}` : 'Unknown Student';
          dayStudentMap[dayName].push(studentName);
        }
      }
    });

    return DAYS_OF_WEEK.map(day => ({
      day,
      count: dayCounts[day],
      students: dayStudentMap[day]
    }));
  };

  const processStudentData = () => {
    const studentCounts = {};
    const studentMap = {};

    visits.forEach(visit => {
      if (applyStrategiesFilter(visit)) {
        const student = students[visit.studentId];
        const studentName = student ? `${student.firstName} ${student.lastName}` : 'Unknown Student';
        studentCounts[studentName] = (studentCounts[studentName] || 0) + 1;
        if (!studentMap[studentName]) {
          studentMap[studentName] = [];
        }
        studentMap[studentName].push(studentName);
      }
    });

    return Object.entries(studentCounts).map(([name, count]) => ({
      name,
      count,
      students: studentMap[name]
    }));
  };

  const processCategoryData = (type) => {
    const categoryCounts = { 'Not Set': 0 };
    const categoryStudentMap = { 'Not Set': [] };
    const categories = type === 'visit' ? visitCategories : studentCategories;

    visits.forEach(visit => {
      if (applyStrategiesFilter(visit)) {
        const visitCategories = type === 'visit' ? visit.categories : (students[visit.studentId]?.categories || []);
        const student = students[visit.studentId];
        const studentName = student ? `${student.firstName} ${student.lastName}` : 'Unknown Student';

        if (visitCategories && visitCategories.length > 0) {
          visitCategories.forEach(categoryId => {
            const categoryName = categories[categoryId] || 'Unknown Category';
            categoryCounts[categoryName] = (categoryCounts[categoryName] || 0) + 1;
            if (!categoryStudentMap[categoryName]) {
              categoryStudentMap[categoryName] = [];
            }
            categoryStudentMap[categoryName].push(studentName);
          });
        } else {
          categoryCounts['Not Set']++;
          categoryStudentMap['Not Set'].push(studentName);
        }
      }
    });

    return Object.entries(categoryCounts).map(([category, count]) => ({
      category,
      count,
      students: categoryStudentMap[category]
    }));
  };

  const processRoomData = () => {
    const roomCounts = { 'Not Set': 0 };
    const roomStudentMap = { 'Not Set': [] };

    visits.forEach(visit => {
      if (applyStrategiesFilter(visit)) {
        const roomName = rooms[visit.roomId] || 'Not Set';
        roomCounts[roomName] = (roomCounts[roomName] || 0) + 1;
        const student = students[visit.studentId];
        const studentName = student ? `${student.firstName} ${student.lastName}` : 'Unknown Student';
        if (!roomStudentMap[roomName]) {
          roomStudentMap[roomName] = [];
        }
        roomStudentMap[roomName].push(studentName);
      }
    });

    return Object.entries(roomCounts).map(([room, count]) => ({
      room,
      count,
      students: roomStudentMap[room]
    }));
  };

  const processDayData = () => {
    const dayCounts = {};
    const dayStudentMap = {};

    visits.forEach(visit => {
      if (applyStrategiesFilter(visit)) {
        const day = format(new Date(visit.timestamp), 'yyyy-MM-dd');
        dayCounts[day] = (dayCounts[day] || 0) + 1;
        const student = students[visit.studentId];
        const studentName = student ? `${student.firstName} ${student.lastName}` : 'Unknown Student';
        if (!dayStudentMap[day]) {
          dayStudentMap[day] = [];
        }
        dayStudentMap[day].push(studentName);
      }
    });

    return Object.entries(dayCounts).map(([date, count]) => ({ 
      date, 
      count, 
      students: dayStudentMap[date],
      formattedDate: format(new Date(date), 'MMM d, yyyy') 
    }));
  };

  const processMonthData = () => {
    const monthCounts = {};
    const monthStudentMap = {};

    visits.forEach(visit => {
      if (applyStrategiesFilter(visit)) {
        const month = format(new Date(visit.timestamp), 'yyyy-MM');
        monthCounts[month] = (monthCounts[month] || 0) + 1;
        const student = students[visit.studentId];
        const studentName = student ? `${student.firstName} ${student.lastName}` : 'Unknown Student';
        if (!monthStudentMap[month]) {
          monthStudentMap[month] = [];
        }
        monthStudentMap[month].push(studentName);
      }
    });

    return Object.entries(monthCounts).map(([month, count]) => ({ 
      month, 
      count, 
      students: monthStudentMap[month],
      formattedMonth: format(new Date(month), 'MMMM yyyy') 
    }));
  };

  const renderTooltipContent = ({ payload }) => {
    if (!payload || !payload.length) return null;

    const { count, students } = payload[0].payload;

    if (count === 0) return null;

    return (
      <div className="custom-tooltip">
        <p className="tooltip-label">{`${count} Visits:`}</p>
        <ul className="tooltip-list">
          {students.map((student, index) => (
            <li key={index} className="tooltip-item">{student}</li>
          ))}
        </ul>
      </div>
    );
  };

  const renderChart = () => {
    let xKey, yKey, nameKey;
    switch(selectedChart.value) {
      case 'hourOfDay':
        xKey = 'hour';
        yKey = 'count';
        nameKey = 'hour';
        break;
      case 'dayOfWeek':
        xKey = 'day';
        yKey = 'count';
        nameKey = 'day';
        break;
      case 'student':
        xKey = 'name';
        yKey = 'count';
        nameKey = 'name';
        break;
      case 'visitCategory':
      case 'studentCategory':
        xKey = 'category';
        yKey = 'count';
        nameKey = 'category';
        break;
      case 'room':
        xKey = 'room';
        yKey = 'count';
        nameKey = 'room';
        break;
      case 'day':
        xKey = 'date';
        yKey = 'count';
        nameKey = 'formattedDate';
        break;
      case 'month':
        xKey = 'month';
        yKey = 'count';
        nameKey = 'formattedMonth';
        break;
      default:
        return null;
    }

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
      const RADIAN = Math.PI / 180;
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
      return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {`${chartData[index][nameKey]} (${(percent * 100).toFixed(0)}%)`}
        </text>
      );
    };

    switch(chartType.value) {
      case 'bar':
      case 'line':
        const ChartComponent = chartType.value === 'bar' ? BarChart : LineChart;
        const DataComponent = chartType.value === 'bar' ? Bar : Line;
        return (
          <ResponsiveContainer width="100%" height={400}>
            <ChartComponent data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey={xKey} 
                angle={-45} 
                textAnchor="end" 
                height={80}
                interval={0}
              />
              <YAxis 
                allowDecimals={false}
                interval={0}
              />
              <Tooltip content={renderTooltipContent} />
              <Legend />
              <DataComponent type="monotone" dataKey={yKey} fill="#8884d8" stroke="#8884d8" />
            </ChartComponent>
          </ResponsiveContainer>
        );
      case 'pie':
        return (
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie
                data={chartData}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={150}
                fill="#8884d8"
                dataKey={yKey}
                nameKey={nameKey}
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip content={renderTooltipContent} />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        );
      default:
        return null;
    }
  };

  return (
    <div className="dashboard-charts">
      <h2>Visit Analytics</h2>
      <div className="chart-controls-container">
        <div className="chart-control">
          <label htmlFor="chart-select">Chart Type:</label>
          <Select
            id="chart-select"
            value={selectedChart}
            onChange={setSelectedChart}
            options={chartOptions}
            className="react-select-container"
            classNamePrefix="react-select"
          />
        </div>
        <div className="chart-control">
          <label htmlFor="chart-type-select">Display As:</label>
          <Select
            id="chart-type-select"
            value={chartType}
            onChange={setChartType}
            options={chartTypeOptions}
            className="react-select-container"
            classNamePrefix="react-select"
          />
        </div>
      </div>
      <div className="chart-container" ref={chartRef}>
        <h3>{`Visits by ${selectedChart.label.split(' ').slice(-2).join(' ')}`}</h3>
        {renderChart()}
      </div>
    </div>
  );
};

export default DashboardCharts;