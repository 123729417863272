import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, database } from "../../../firebase"; // Adjusted the import path
import { ref, push, remove, onValue } from "firebase/database";
import { FaInfoCircle, FaChevronDown, FaChevronUp } from "react-icons/fa";
import "./CustomCategoriesManager.css";

const CustomCategoriesManager = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const categoriesRef = ref(database, `users/${user.uid}/SupportRoom/categories`);
      onValue(categoriesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const categoryList = Object.entries(data).map(([key, value]) => ({
            id: key,
            name: value,
          }));
          setCategories(categoryList);
        } else {
          setCategories([]);
        }
      });
    }
  }, []);

  const normalizeString = (str) => {
    return str.replace(/\s+/g, '').toLowerCase(); // Remove all spaces and convert to lowercase
  };

  const handleCategoryInputChange = (e) => {
    const value = e.target.value;
    setNewCategory(value);

    // Check for duplicates by normalizing the input and existing categories
    const normalizedValue = normalizeString(value);

    const duplicate = categories.some(
      (category) => normalizeString(category.name) === normalizedValue
    );
    setIsDuplicate(duplicate);
  };

  const addCategory = () => {
    if (newCategory.trim() !== "" && !isDuplicate) {
      const user = auth.currentUser;
      const categoriesRef = ref(database, `users/${user.uid}/SupportRoom/categories`);
      push(categoriesRef, newCategory.trim());
      setNewCategory("");
    }
  };

  const deleteCategory = (categoryId) => {
    const user = auth.currentUser;
    const categoryRef = ref(
      database,
      `users/${user.uid}/SupportRoom/categories/${categoryId}`
    );
    remove(categoryRef);
  };

  const handleBack = () => {
    navigate("/support-room-tracker/dashboard");
  };

  return (
    <div className="custom-categories-manager">
      <div className="custom-categories-manager-header">
        <button onClick={handleBack} className="back-button">
          &larr; Back to Dashboard
        </button>
        <h2 className="custom-categories-manager-title">
          Manage Student Categories
        </h2>
      </div>

      <div className="info-section">
        <button className="info-toggle" onClick={() => setShowInfo(!showInfo)}>
          <FaInfoCircle /> Information about Student Categories
          {showInfo ? <FaChevronUp /> : <FaChevronDown />}
        </button>
        {showInfo && (
          <div className="info-content">
            <p>
              Student categories are used to classify and group students based
              on various characteristics, needs, or programs they're involved
              in. These categories help in organizing student data and tailoring
              support strategies.
            </p>
            <p>
              Examples of student categories might include: "ISP", "ELL",
              "Gifted Program", "At-Risk", etc.
            </p>
            <p>
              Note: These categories are for classifying students themselves and
              should not be confused with visit categories, which are used to
              categorize reasons for visits to the support room.
            </p>
          </div>
        )}
      </div>

      <div className="add-category-form">
        <input
          type="text"
          value={newCategory}
          onChange={handleCategoryInputChange}
          placeholder="New Student Category"
          className="category-input"
        />
        <button
          onClick={addCategory}
          className="add-category-button"
          disabled={isDuplicate || newCategory.trim() === ""}
        >
          Add Category
        </button>
      </div>

      {isDuplicate && (
        <p className="error-message">This category already exists.</p>
      )}

      <ul className="category-list">
        {categories.map((category) => (
          <li key={category.id} className="category-item">
            <span>{category.name}</span>
            <button
              onClick={() => deleteCategory(category.id)}
              className="delete-category-button"
            >
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CustomCategoriesManager;
