// src/apps/AIAssistants/AssistantsGallery.js
import React from 'react';
import { FaRobot } from 'react-icons/fa';
import './AssistantsGallery.css';

function AssistantsGallery({ assistants }) {
  return (
    <div className="assistants-gallery">
      {assistants.length === 0 ? (
        <p className="no-assistants-message">No assistants created yet. Click the button above to create your first assistant!</p>
      ) : (
        <div className="assistants-grid">
          {assistants.map((assistant) => (
            <div key={assistant.id} className="assistant-card">
              <div className="assistant-icon">
                <FaRobot />
              </div>
              <h3 className="assistant-name">{assistant.name}</h3>
              <p className="assistant-description">{assistant.description}</p>
              <button className="edit-assistant-btn">Edit</button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default AssistantsGallery;