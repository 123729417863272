import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSignOutAlt, FaEdit } from 'react-icons/fa';
import './SupportHeader.css';

const SupportHeader = ({ 
  user, 
  customTitle, 
  isEditingTitle, 
  onSignOut, 
  onEditTitle, 
  onTitleChange, 
  onTitleSubmit 
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const controlHeader = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY === 0) {
        setIsVisible(true);
      } else if (currentScrollY > lastScrollY) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', controlHeader);

    return () => {
      window.removeEventListener('scroll', controlHeader);
    };
  }, [lastScrollY]);

  const handleEdbotzClick = () => {
    navigate('/dashboard');
  };

  return (
    <header className={`support-room-header ${isVisible ? '' : 'support-room-hidden'}`}>
      <div className="support-room-header-content">
        <div className="support-room-branding">
          <h1 className="support-room-company-name" onClick={handleEdbotzClick}>edbotz</h1>
          <h2 className="support-room-dashboard-title">
            {isEditingTitle ? (
              <input
                type="text"
                value={customTitle}
                onChange={onTitleChange}
                onBlur={onTitleSubmit}
                autoFocus
              />
            ) : (
              <>
                {customTitle}
                <button onClick={onEditTitle} className="support-room-edit-title-button">
                  <FaEdit />
                </button>
              </>
            )}
          </h2>
        </div>
        <div className="support-room-user-info">
          <span className="support-room-welcome-message">Welcome, {user.displayName}</span>
          <button onClick={onSignOut} className="support-room-signout-button">
            <FaSignOutAlt /> Sign Out
          </button>
        </div>
      </div>
    </header>
  );
};

export default SupportHeader;