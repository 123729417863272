import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, database } from "../../../firebase";
import { ref, push, remove, onValue } from "firebase/database";
import "./RoomManager.css";

const RoomManager = () => {
  const [rooms, setRooms] = useState([]);
  const [newRoomName, setNewRoomName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const roomsRef = ref(database, `users/${user.uid}/SupportRoom/rooms`);
      onValue(roomsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const roomList = Object.entries(data).map(([key, value]) => ({
            id: key,
            name: value,
          }));
          setRooms(roomList);
        } else {
          setRooms([]);
        }
      });
    }
  }, []);

  const addRoom = () => {
    if (newRoomName.trim() !== "") {
      const user = auth.currentUser;
      const roomsRef = ref(database, `users/${user.uid}/SupportRoom/rooms`);
      push(roomsRef, newRoomName.trim());
      setNewRoomName("");
    }
  };

  const removeRoom = (roomId) => {
    const user = auth.currentUser;
    const roomRef = ref(database, `users/${user.uid}/SupportRoom/rooms/${roomId}`);
    remove(roomRef);
  };

  const handleBack = () => {
    navigate("/support-room-tracker/dashboard");
  };
  

  return (
    <div className="room-manager">
      <div className="room-manager-header">
        <button onClick={handleBack} className="back-button">
          &larr; Back to Dashboard
        </button>
        <h2 className="room-manager-title">Manage Rooms</h2>
      </div>
      <div className="add-room-form">
        <input
          type="text"
          value={newRoomName}
          onChange={(e) => setNewRoomName(e.target.value)}
          placeholder="Enter room name"
          className="room-input"
        />
        <button onClick={addRoom} className="add-room-button">
          Add Room
        </button>
      </div>
      <ul className="room-list">
        {rooms.map((room) => (
          <li key={room.id} className="room-item">
            <span>{room.name}</span>
            <button
              onClick={() => removeRoom(room.id)}
              className="remove-room-button"
            >
              Remove
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RoomManager;
