import React, { useState, useEffect } from "react";
import { auth, database } from "../../../firebase";
import { ref, push, onValue } from "firebase/database";
import { FaFlag, FaTimes } from "react-icons/fa";
import Select from 'react-select';
import "./QuickAddVisit.css";

const QuickAddVisit = ({ onVisitAdded, rooms, visitCategories, strategies }) => {
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [newStudent, setNewStudent] = useState({ firstName: "", lastName: "" });
  const [isAddingNewStudent, setIsAddingNewStudent] = useState(false);
  const [isFlagged, setIsFlagged] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [selectedVisitCategory, setSelectedVisitCategory] = useState(null);
  const [selectedStrategy, setSelectedStrategy] = useState(null);

  const roomOptions = Object.entries(rooms).map(([id, name]) => ({ value: id, label: name }));
  const visitCategoryOptions = Object.entries(visitCategories).map(([id, name]) => ({ value: id, label: name }));
  const strategyOptions = Object.entries(strategies).map(([id, name]) => ({ value: id, label: name }));

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const studentsRef = ref(database, `users/${user.uid}/SupportRoom/students`);
      onValue(studentsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const studentList = Object.entries(data).map(([key, value]) => ({
            value: key,
            label: `${value.firstName} ${value.lastName}`,
          }));
          setStudents(studentList);
        }
      });
    }
  }, []);

  const handleAddNewStudent = () => {
    setIsAddingNewStudent(true);
    setSelectedStudent(null);
  };

  const handleQuickAddVisit = async () => {
    const user = auth.currentUser;
    if (user) {
      const visitsRef = ref(database, `users/${user.uid}/SupportRoom/visits`);
      let studentId = selectedStudent ? selectedStudent.value : null;
  
      try {
        if (isAddingNewStudent) {
          const studentsRef = ref(database, `users/${user.uid}/SupportRoom/students`);
          const newStudentRef = await push(studentsRef, newStudent);
          studentId = newStudentRef.key;
        }
  
        await push(visitsRef, {
          studentId,
          roomId: selectedRoom ? selectedRoom.value : null,
          categories: selectedVisitCategory ? [selectedVisitCategory.value] : [],
          strategies: selectedStrategy ? [selectedStrategy.value] : [],
          timestamp: Date.now(),
          isFlagged,
          isQuickAdd: true,
        });

        // Reset form
        setSelectedStudent(null);
        setNewStudent({ firstName: "", lastName: "" });
        setIsAddingNewStudent(false);
        setIsFlagged(false);
        setSelectedRoom(null);
        setSelectedVisitCategory(null);
        setSelectedStrategy(null);

        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 3000);

        if (onVisitAdded) {
          onVisitAdded();
        }
      } catch (error) {
        console.error("Error adding quick visit:", error);
        alert("Failed to add visit. Please try again.");
      }
    }
  };

  const handleResetSelection = () => {
    setSelectedStudent(null);
    setIsAddingNewStudent(false);
    setSelectedRoom(null);
    setSelectedVisitCategory(null);
    setSelectedStrategy(null);
  };

  const showDropdowns = selectedStudent || isAddingNewStudent;

  return (
    <div className="quick-add-visit">
      <h3 className="quick-add-title">Quick Add Visit</h3>
      <div className="student-selection">
        <Select
          options={students}
          value={selectedStudent}
          onChange={setSelectedStudent}
          placeholder="Search students..."
          isClearable
          isSearchable
          className="react-select-container"
          classNamePrefix="react-select"
        />
      </div>
      {!selectedStudent && !isAddingNewStudent && (
        <button onClick={handleAddNewStudent} className="add-new-student-button">
          Add New Student
        </button>
      )}
      {isAddingNewStudent && (
       <div className="new-student-form">
       <div className="input-wrapper">
         <input
           type="text"
           placeholder="First Name"
           value={newStudent.firstName}
           onChange={(e) =>
             setNewStudent({ ...newStudent, firstName: e.target.value })
           }
         />
         <button onClick={handleResetSelection} className="reset-selection">
           <FaTimes />
         </button>
       </div>
       <div className="input-wrapper">
       <input
         type="text"
         placeholder="Last Name"
         value={newStudent.lastName}
         onChange={(e) =>
           setNewStudent({ ...newStudent, lastName: e.target.value })
         }
       />
     </div>
     </div>
      )}
      
      {showDropdowns && (
        <div className="dropdown-container">
          <Select
            value={selectedRoom}
            onChange={setSelectedRoom}
            options={roomOptions}
            placeholder="Room"
            isClearable
            className="react-select-container"
            classNamePrefix="react-select"
          />
          <Select
            value={selectedVisitCategory}
            onChange={setSelectedVisitCategory}
            options={visitCategoryOptions}
            placeholder="Visit Category"
            isClearable
            className="react-select-container"
            classNamePrefix="react-select"
          />
          <Select
            value={selectedStrategy}
            onChange={setSelectedStrategy}
            options={strategyOptions}
            placeholder="Strategies"
            isClearable
            className="react-select-container"
            classNamePrefix="react-select"
          />
        </div>
      )}
  
      {showDropdowns && (
        <div className="flag-container">
          <label>
            <input
              type="checkbox"
              checked={isFlagged}
              onChange={() => setIsFlagged(!isFlagged)}
            />
            Flag for later update
          </label>
        </div>
      )}
      {showDropdowns && (
        <button
          onClick={handleQuickAddVisit}
          className="quick-add-button"
          disabled={!selectedStudent && !isAddingNewStudent}
        >
          Quick Add Visit
        </button>
      )}
      {showSuccessMessage && (
        <div className="success-message">Student visit added successfully!</div>
      )}
    </div>
  );
};

export default QuickAddVisit;
