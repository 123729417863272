
// src/apps/SupportRoomTracker/src/Dashboard.js

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, database } from "../../../firebase";
import {
  ref,
  query,
  orderByChild,
  startAt,
  endAt,
  onValue,
  get,
  set,
} from "firebase/database";
import { startOfDay, endOfDay } from "date-fns";
import {
  FaUsers,
  FaDoorOpen,
  FaTags,
  FaChevronDown,
  FaChevronUp,
  FaPlus,
} from "react-icons/fa";
import SupportHeader from "./SupportHeader";
import SupportFooter from "./SupportFooter";
import QuickAddVisit from "./QuickAddVisit";
import DashboardCharts from "./DashboardCharts";
import FilterComponent from "./FilterComponent";
import AllVisits from "./AllVisits";
import ExportComponent from "./ExportComponent";
import "./Dashboard.css";
import Select from 'react-select';


// Function to initialize SupportRoom folder under the user's profile
const initializeSupportRoom = async (userId) => {
  const supportRoomRef = ref(database, `users/${userId}/SupportRoom`);
  
  // Check if SupportRoom folder exists for the user
  const snapshot = await get(supportRoomRef);

  if (!snapshot.exists()) {
    // If it doesn't exist, create the necessary folder structure under this user
    await set(supportRoomRef, {
      students: {},
      rooms: {},
      visitCategories: {},
      categories: {},
      strategies: {},
      visits: {},
      customTitle: "GW Smith Support Room Tracker",  // Default title or empty
    });
  } else {
    // If it exists, make sure there's a customTitle set
    const titleSnapshot = await get(ref(database, `users/${userId}/SupportRoom/customTitle`));
    if (!titleSnapshot.exists()) {
      await set(ref(database, `users/${userId}/SupportRoom/customTitle`), "GW Smith Support Room Tracker");
    }
  }
};

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [students, setStudents] = useState({});
  const [rooms, setRooms] = useState({});
  const [visitCategories, setVisitCategories] = useState({});
  const [studentCategories, setStudentCategories] = useState({});
  const [strategies, setStrategies] = useState({});
  const [isCategoryMenuOpen, setIsCategoryMenuOpen] = useState(false);
  const [visits, setVisits] = useState([]);
  const [chartImageData, setChartImageData] = useState(null);
  const [customTitle, setCustomTitle] = useState("GW Smith Support Room Tracker");
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [filters, setFilters] = useState({
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
    selectedStudents: [],
    selectedRooms: [],
    selectedVisitCategories: [],
    selectedStudentCategories: [],
    selectedStrategies: [],
  });

  const [refreshKey, setRefreshKey] = useState(0);
  const [viewModes, setViewModes] = useState({
    visits: true,
    analytics: true,
    export: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

        // Initialize SupportRoom folder if it doesn't exist
        await initializeSupportRoom(currentUser.uid);

        // Fetch data related to the support room
        fetchStudents(currentUser.uid);
        fetchRooms(currentUser.uid);
        fetchVisitCategories(currentUser.uid);
        fetchStudentCategories(currentUser.uid);
        fetchStrategies(currentUser.uid);
        fetchCustomTitle(currentUser.uid);
      } else {
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (user) {
      fetchVisits();
    }
  }, [user, filters, refreshKey]);

  const fetchStudents = (userId) => {
    const studentsRef = ref(database, `users/${userId}/SupportRoom/students`);
    onValue(studentsRef, (snapshot) => {
      const studentsData = snapshot.val() || {};
      setStudents(studentsData);
    });
  };

  const fetchRooms = (userId) => {
    const roomsRef = ref(database, `users/${userId}/SupportRoom/rooms`);
    onValue(roomsRef, (snapshot) => {
      setRooms(snapshot.val() || {});
    });
  };

  const fetchVisitCategories = (userId) => {
    const categoriesRef = ref(database, `users/${userId}/SupportRoom/visitCategories`);
    onValue(categoriesRef, (snapshot) => {
      setVisitCategories(snapshot.val() || {});
    });
  };

  const fetchStudentCategories = (userId) => {
    const categoriesRef = ref(database, `users/${userId}/SupportRoom/categories`);
    onValue(categoriesRef, (snapshot) => {
      setStudentCategories(snapshot.val() || {});
    });
  };

  const fetchStrategies = (userId) => {
    const strategiesRef = ref(database, `users/${userId}/SupportRoom/strategies`);
    onValue(strategiesRef, (snapshot) => {
      const strategiesData = snapshot.val() || {};
      setStrategies(strategiesData);
    });
  };

  const fetchCustomTitle = (userId) => {
    const titleRef = ref(database, `users/${userId}/SupportRoom/customTitle`);
    onValue(titleRef, (snapshot) => {
      const title = snapshot.val();
      if (title) {
        setCustomTitle(title);
      }
    });
  };

  const fetchVisits = async () => {
    const user = auth.currentUser;
    if (user) {
      const visitsRef = ref(database, `users/${user.uid}/SupportRoom/visits`);
      const visitsQuery = query(
        visitsRef,
        orderByChild("timestamp"),
        startAt(filters.startDate.getTime()),
        endAt(filters.endDate.getTime())
      );

      try {
        const snapshot = await get(visitsQuery);
        let visitsData = [];
        snapshot.forEach((childSnapshot) => {
          visitsData.push({
            id: childSnapshot.key,
            ...childSnapshot.val(),
          });
        });

        // Apply filters
        visitsData = visitsData.filter(visit => {
          const student = students[visit.studentId];

          if (filters.selectedStudents.length > 0 && !filters.selectedStudents.some(s => s.value === visit.studentId)) {
            return false;
          }

          if (filters.selectedRooms.length > 0) {
            if (filters.selectedRooms.some(r => r.value === 'not_set')) {
              if (visit.roomId && !filters.selectedRooms.some(r => r.value === visit.roomId)) {
                return false;
              }
            } else if (!filters.selectedRooms.some(r => r.value === visit.roomId)) {
              return false;
            }
          }

          if (filters.selectedVisitCategories.length > 0) {
            if (filters.selectedVisitCategories.some(vc => vc.value === 'not_set')) {
              if (visit.categories && visit.categories.length > 0 && 
                  !visit.categories.some(cat => filters.selectedVisitCategories.some(sc => sc.value === cat))) {
                return false;
              }
            } else if (!visit.categories || !visit.categories.some(cat => filters.selectedVisitCategories.some(sc => sc.value === cat))) {
              return false;
            }
          }

          if (filters.selectedStudentCategories.length > 0) {
            if (filters.selectedStudentCategories.some(sc => sc.value === 'not_set')) {
              if (student.categories && student.categories.length > 0 && 
                  !student.categories.some(cat => filters.selectedStudentCategories.some(sc => sc.value === cat))) {
                return false;
              }
            } else if (!student.categories || !student.categories.some(cat => filters.selectedStudentCategories.some(sc => sc.value === cat))) {
              return false;
            }
          }

          if (filters.selectedStrategies.length > 0) {
            if (!visit.strategies || !visit.strategies.some(str => filters.selectedStrategies.some(fs => fs.value === str))) {
              return false;
            }
          }

          return true;
        });

        setVisits(visitsData);
      } catch (error) {
        console.error("Error fetching visits:", error);
      }
    }
  };

  const handleSignOut = () => {
    auth
      .signOut()
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.error("Sign-out error:", error);
      });
  };

  const toggleCategoryMenu = () => {
    setIsCategoryMenuOpen(!isCategoryMenuOpen);
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  const handleViewModeChange = (newViewModes) => {
    if (newViewModes.export) {
      newViewModes.visits = true;
      newViewModes.analytics = true;
    }
    setViewModes(newViewModes);
  };

  const refreshData = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const handleChartImageChange = (imageData) => {
    setChartImageData(imageData);
  };

  const handleEditTitle = (e) => {
    e.preventDefault();
    setIsEditingTitle(true);
  };

  const handleTitleChange = (e) => {
    setCustomTitle(e.target.value);
  };

  const handleTitleSubmit = async () => {
    const user = auth.currentUser;
    if (user) {
      const titleRef = ref(database, `users/${user.uid}/SupportRoom/customTitle`);
      try {
        await set(titleRef, customTitle);
        setIsEditingTitle(false);
      } catch (error) {
        console.error("Error saving custom title:", error);
      }
    }
  };

  if (!user) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="support-room-tracker">
    <div className="dashboard">
      <SupportHeader 
        user={user}
        customTitle={customTitle}
        isEditingTitle={isEditingTitle}
        onSignOut={handleSignOut}
        onEditTitle={handleEditTitle}
        onTitleChange={handleTitleChange}
        onTitleSubmit={handleTitleSubmit}
      />

      <div className="dashboard-content">
      <div className="dashboard-nav-container">
  <nav className="dashboard-nav">
    <div className="nav-quick-add-visit">
      <QuickAddVisit 
        onVisitAdded={refreshData}
        rooms={rooms}
        visitCategories={visitCategories}
        strategies={strategies}
      />
    </div>
    
    <Link to="/support-room-tracker/new-visit" className="nav-link">
      <FaPlus /> New Visit
    </Link>
    <Link to="/support-room-tracker/room-manager" className="nav-link">
      <FaDoorOpen /> Rooms
    </Link>
    <Link to="/support-room-tracker/student-manager" className="nav-link">
      <FaUsers /> Students
    </Link>


    <div className="category-dropdown">
  <Select
    options={[
      { value: '/support-room-tracker/category-manager', label: 'Student Categories' },
      { value: '/support-room-tracker/visit-category-manager', label: 'Visit Categories' },
      { value: '/support-room-tracker/strategies-manager', label: 'Strategies' },
    ]}
    placeholder={
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FaTags style={{ marginRight: 8 }} />
        Categories
      </div>
    }
    onChange={(selectedOption) => {
      if (selectedOption) {
        navigate(selectedOption.value);
      }
    }}
    styles={{
      control: (base) => ({
        ...base,
        width: '200px',  // Set the width here
      }),
    }}
  />
</div>

  </nav>
</div>
        <main className="dashboard-main">
          <section className="dashboard-section filter-section">
            <FilterComponent
              onFilterChange={handleFilterChange}
              onViewModeChange={handleViewModeChange}
              viewModes={viewModes}
            />
          </section>

          {viewModes.export && (
            <section className="dashboard-section">
              <ExportComponent
                filters={filters}
                visits={visits}
                students={students}
                rooms={rooms}
                visitCategories={visitCategories}
                studentCategories={studentCategories}
                strategies={strategies}
                chartImageData={chartImageData}
                customTitle={customTitle}
              />
            </section>
          )}

          {viewModes.analytics && (
            <section className="dashboard-section">
              <DashboardCharts 
                filters={{
                  ...filters,
                  students,
                  rooms,
                  visitCategories,
                  studentCategories
                }}
                visits={visits}
                onChartImageChange={handleChartImageChange}
              />
            </section>
          )}

          {viewModes.visits && (
            <section className="dashboard-section">
              <AllVisits 
                filters={{
                  ...filters,
                  students,
                  rooms,
                  visitCategories,
                  studentCategories,
                  strategies
                }}
                visits={visits}
                onVisitsUpdated={refreshData}
              />
            </section>
          )}
        </main>
      </div>
      <SupportFooter />
    </div>
    </div>
  );
};

export default Dashboard;