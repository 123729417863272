// FilterComponent.js
import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { format, startOfDay, endOfDay, subMonths, isToday, startOfMonth, endOfMonth } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { auth, database } from "../../../firebase";
import { ref, get } from "firebase/database";
import { FaInfoCircle } from 'react-icons/fa';
import './FilterComponent.css';

const FilterComponent = ({ onFilterChange, onViewModeChange, viewModes }) => {
  const [startDate, setStartDate] = useState(startOfDay(new Date()));
  const [endDate, setEndDate] = useState(endOfDay(new Date()));
  const [quickSelect, setQuickSelect] = useState('today');
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [selectedVisitCategories, setSelectedVisitCategories] = useState([]);
  const [selectedStudentCategories, setSelectedStudentCategories] = useState([]);
  const [selectedStrategies, setSelectedStrategies] = useState([]);
  const [students, setStudents] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [visitCategories, setVisitCategories] = useState([]);
  const [studentCategories, setStudentCategories] = useState([]);
  const [strategies, setStrategies] = useState([]);
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    fetchFilterOptions();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [startDate, endDate, selectedStudents, selectedRooms, selectedVisitCategories, selectedStudentCategories, selectedStrategies]);

  const fetchFilterOptions = async () => {
    const user = auth.currentUser;
    if (user) {
      const studentsRef = ref(database, `users/${user.uid}/SupportRoom/students`);
      const roomsRef = ref(database, `users/${user.uid}/SupportRoom/rooms`);
      const visitCategoriesRef = ref(database, `users/${user.uid}/SupportRoom/visitCategories`);
      const studentCategoriesRef = ref(database, `users/${user.uid}/SupportRoom/categories`);
      const strategiesRef = ref(database, `users/${user.uid}/SupportRoom/strategies`);

      const [studentsSnapshot, roomsSnapshot, visitCategoriesSnapshot, studentCategoriesSnapshot, strategiesSnapshot] = await Promise.all([
        get(studentsRef),
        get(roomsRef),
        get(visitCategoriesRef),
        get(studentCategoriesRef),
        get(strategiesRef)
      ]);

      setStudents(
        Object.entries(studentsSnapshot.val() || {}).map(([id, student]) => ({
          value: id,
          label: `${student.firstName} ${student.lastName}`
        }))
      );

      setRooms([
        { value: 'not_set', label: 'Not Set' },
        ...Object.entries(roomsSnapshot.val() || {}).map(([id, name]) => ({
          value: id,
          label: name
        }))
      ]);

      setVisitCategories([
        { value: 'not_set', label: 'Not Set' },
        ...Object.entries(visitCategoriesSnapshot.val() || {}).map(([id, name]) => ({
          value: id,
          label: name
        }))
      ]);

      setStudentCategories([
        { value: 'not_set', label: 'Not Set' },
        ...Object.entries(studentCategoriesSnapshot.val() || {}).map(([id, name]) => ({
          value: id,
          label: name
        }))
      ]);

      setStrategies(
        Object.entries(strategiesSnapshot.val() || {}).map(([id, name]) => ({
          value: id,
          label: name
        }))
      );
    }
  };

  const getCurrentSchoolYear = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    return month < 8 ? year - 1 : year;
  };

  const formatSchoolYear = (year) => {
    return `${year.toString().slice(-2)}/${(year + 1).toString().slice(-2)}`;
  };

  const handleQuickSelect = (option) => {
    const today = new Date();
    const currentSchoolYear = getCurrentSchoolYear();
    switch (option) {
      case 'today':
        setStartDate(startOfDay(today));
        setEndDate(endOfDay(today));
        break;
      case 'thisMonth':
        setStartDate(startOfMonth(today));
        setEndDate(endOfDay(today));
        break;
      case 'lastMonth':
        const lastMonth = subMonths(today, 1);
        setStartDate(startOfMonth(lastMonth));
        setEndDate(endOfMonth(lastMonth));
        break;
      case 'thisYear':
        setStartDate(new Date(currentSchoolYear, 8, 1));
        setEndDate(endOfDay(today));
        break;
      case 'lastYear':
        setStartDate(new Date(currentSchoolYear - 1, 8, 1));
        setEndDate(new Date(currentSchoolYear, 7, 31));
        break;
      case 'custom':
        // Do nothing, allow manual selection
        break;
    }
    setQuickSelect(option);
  };

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button className="custom-input" onClick={onClick} ref={ref}>
      {isToday(new Date(value)) ? 'Today' : format(new Date(value), 'MMM d, yyyy')}
    </button>
  ));

  const applyFilters = () => {
    onFilterChange({
      startDate,
      endDate,
      selectedStudents,
      selectedRooms,
      selectedVisitCategories,
      selectedStudentCategories,
      selectedStrategies,
    });
  };

  const toggleViewMode = (mode) => {
    const newViewModes = { ...viewModes, [mode]: !viewModes[mode] };
    if (mode === 'export' && newViewModes.export) {
      newViewModes.visits = true;
      newViewModes.analytics = true;
    }
    onViewModeChange(newViewModes);
  };

  const toggleInfo = () => {
    setShowInfo(!showInfo);
  };

  return (
    <div className="filters-container">
      <div className="filters-header">
        <h2 className="filters-title">
          Data Filters
          <button className="info-button" onClick={toggleInfo}>
            <FaInfoCircle />
          </button>
        </h2>
        {showInfo && (
          <div className="info-message">
            These filters help you choose which student visits you want to see. When you change a filter:
            <ul>
              <li>The list of visits below will update to show only the visits that match your choices.</li>
              <li>The charts and analytics will also change to reflect the visits you've selected.</li>
              <li>You can combine filters to narrow down the information even more.</li>
            </ul>
            Try different filters to focus on the information that's most important to you!
          </div>
        )}
      </div>
      <div className="filters-body">
        <div className="date-controls">
          <Select
            options={[
              { value: 'today', label: 'Today' },
              { value: 'thisMonth', label: 'This Month' },
              { value: 'lastMonth', label: 'Last Month' },
              { value: 'thisYear', label: `This Year (${formatSchoolYear(getCurrentSchoolYear())})` },
              { value: 'lastYear', label: `Last Year (${formatSchoolYear(getCurrentSchoolYear() - 1)})` },
              { value: 'custom', label: 'Custom Range' },
            ]}
            value={{ value: quickSelect, label: quickSelect.charAt(0).toUpperCase() + quickSelect.slice(1) }}
            onChange={(selectedOption) => handleQuickSelect(selectedOption.value)}
            className="quick-select-container"
            classNamePrefix="quick-select"
          />
          {quickSelect !== 'today' && (
            <div className="date-pickers">
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  setQuickSelect('custom');
                }}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                customInput={<CustomInput />}
              />
              <DatePicker
                selected={endDate}
                onChange={(date) => {
                  setEndDate(date);
                  setQuickSelect('custom');
                }}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                customInput={<CustomInput />}
              />
            </div>
          )}
        </div>
        <div className="other-filters">
          <Select
            options={students}
            value={selectedStudents}
            onChange={setSelectedStudents}
            placeholder="Filter by Students"
            isMulti
            isClearable
          />
          <Select
            options={rooms}
            value={selectedRooms}
            onChange={setSelectedRooms}
            placeholder="Filter by Rooms"
            isMulti
            isClearable
          />
          <Select
            options={visitCategories}
            value={selectedVisitCategories}
            onChange={setSelectedVisitCategories}
            placeholder="Filter by Visit Categories"
            isMulti
            isClearable
          />
          <Select
            options={studentCategories}
            value={selectedStudentCategories}
            onChange={setSelectedStudentCategories}
            placeholder="Filter by Student Categories"
            isMulti
            isClearable
          />
          <Select
            options={strategies}
            value={selectedStrategies}
            onChange={setSelectedStrategies}
            placeholder="Filter by Strategies"
            isMulti
            isClearable
          />
        </div>
      </div>
      <div className="view-mode-section">
        <label className="view-mode-label">Display Options:</label>
        <div className="view-mode-buttons">
          <button
            className={`view-mode-button ${viewModes.visits ? 'active' : ''}`}
            onClick={() => toggleViewMode('visits')}
            disabled={viewModes.export}
          >
            Visits
          </button>
          <button
            className={`view-mode-button ${viewModes.analytics ? 'active' : ''}`}
            onClick={() => toggleViewMode('analytics')}
            disabled={viewModes.export}
          >
            Analytics
          </button>
          <button
            className={`view-mode-button ${viewModes.export ? 'active' : ''}`}
            onClick={() => toggleViewMode('export')}
          >
            Export
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterComponent;