// src/apps/AIAssistants/AssistantsDashboard.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlus, FaSignOutAlt } from 'react-icons/fa';
import { database, auth } from '../../firebase';
import { ref, onValue, set, get } from 'firebase/database';
import { signOut } from 'firebase/auth';
import CreateAssistant from './CreateAssistant';
import AssistantsGallery from './AssistantsGallery';
import './AssistantsDashboard.css';

function AssistantsDashboard() {
  const [assistants, setAssistants] = useState([]);
  const [user, setUser] = useState(null);
  const [showCreateAssistant, setShowCreateAssistant] = useState(false);
  const navigate = useNavigate();

  const initializeUserStructure = async (userId) => {
    const aiAssistantsRef = ref(database, `users/${userId}/AIAssistants`);
    
    try {
      const snapshot = await get(aiAssistantsRef);
      if (!snapshot.exists()) {
        // Create AIAssistants node with an initialization timestamp
        await set(aiAssistantsRef, {
          initialized: Date.now()
        });
        console.log("AIAssistants node created for user");
      }
    } catch (error) {
      console.error("Error initializing user structure:", error);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        initializeUserStructure(currentUser.uid);
      } else {
        // Redirect to login if no user is authenticated
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (!user) return;

    const assistantsRef = ref(database, `users/${user.uid}/AIAssistants`);
    const unsubscribe = onValue(assistantsRef, (snapshot) => {
      const data = snapshot.val();
      if (data && typeof data === 'object') {
        // Filter out the 'initialized' key-value pair
        const assistantList = Object.entries(data)
          .filter(([key]) => key !== 'initialized')
          .map(([id, assistant]) => ({
            id,
            ...assistant,
          }));
        setAssistants(assistantList);
      } else {
        setAssistants([]);
      }
    });

    return () => unsubscribe();
  }, [user]);

  const handleCreateAssistant = () => {
    setShowCreateAssistant(true);
  };

  const handleCloseCreateAssistant = () => {
    setShowCreateAssistant(false);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const getUserDisplayName = () => {
    if (user) {
      return user.displayName || user.email.split('@')[0] || 'User';
    }
    return 'User';
  };

  return (
    <div className="assistants-dashboard">
      <header className="dashboard-header">
        <div className="header-content">
          <div className="branding">
            <h1 className="company-name">edbotz</h1>
            <h2 className="dashboard-title">AI Assistants</h2>
          </div>
          <div className="user-info">
            <span className="welcome-message">Welcome, {getUserDisplayName()}</span>
            <button onClick={handleLogout} className="signout-button">
              <FaSignOutAlt /> Sign Out
            </button>
          </div>
        </div>
      </header>

      <main className="dashboard-main">
        <div className="main-header">
          <h2 className="assistants-title">Your AI Assistants</h2>
          <button className="create-assistant-btn" onClick={handleCreateAssistant}>
            <FaPlus /> Create New Assistant
          </button>
        </div>

        <AssistantsGallery assistants={assistants} />

        {showCreateAssistant && (
          <CreateAssistant onClose={handleCloseCreateAssistant} />
        )}
      </main>

      <footer className="dashboard-footer">
        <p>&copy; 2024 edbotz.com. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default AssistantsDashboard;