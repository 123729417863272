import React from 'react';
import { useNavigate } from 'react-router-dom';
import './TermsOfService.css';

const TermsOfService = () => {
  const navigate = useNavigate();

  return (
    <div className="edbotz-terms-of-service">
      <div className="tos-container">
        <button className="tos-back-button" onClick={() => navigate('/login')}>
          Back to Login
        </button>
        <h2 className="tos-title">Edbotz Terms of Service</h2>
        <p className="tos-date">Last Updated: September 10, 2024</p>

        <section>
          <h3>1. Introduction</h3>
          <p>
            Welcome to Edbotz, a software company providing educational technology solutions. These Terms of Service ("Terms") govern your use of Edbotz applications and services (collectively, the "Service"). By using any Edbotz Service, you agree to these Terms. If you disagree with any part of the terms, you may not use our Services.
          </p>
        </section>

        <section>
          <h3>2. FOIP Compliance</h3>
          <p>
            Edbotz is committed to complying with the Freedom of Information and Protection of Privacy Act (FOIP) of Alberta, Canada. Our practices align with FOIP requirements in the following ways:
          </p>
          <h4>2.1 Collection of Personal Information (FOIP Sections 33-34)</h4>
          <ul className="tos-bullet-points">
            <li>Edbotz only collects personal information necessary for delivering our services.</li>
            <li>Users retain control over what data is input into our systems.</li>
            <li>All personal information collected is used exclusively for legitimate purposes as outlined in our services.</li>
            <li>We are transparent about the purposes of data collection and ensure that data is only collected for the stated purposes.</li>
            <li>For student data, we require confirmation that parental consent has been obtained through the school's FOIP consent process before any student information is entered into our system.</li>
          </ul>

          <h4>2.2 Accuracy and Retention of Personal Information (FOIP Section 35)</h4>
          <ul className="tos-bullet-points">
            <li>Edbotz allows users to update or correct personal data as necessary to ensure accuracy.</li>
            <li>Personal information is retained only for as long as necessary to fulfill its purpose.</li>
            <li>Users can manage their data retention, and our systems provide options for data anonymization or deletion.</li>
            <li>We recommend regular data reviews to ensure compliance with FOIP's retention guidelines.</li>
          </ul>

          <h4>2.3 Protection of Personal Information (FOIP Section 38)</h4>
          <ul className="tos-bullet-points">
            <li>Edbotz implements robust security measures through Google Firebase, which complies with industry-leading security standards.</li>
            <li>All personal data is encrypted both at rest and during transmission to prevent unauthorized access.</li>
            <li>Only authorized personnel with verified credentials can access personal information, ensuring strict data segregation.</li>
            <li>We offer multiple secure authentication methods, including Google Sign-In, Microsoft Sign-In, and email verification. School domains can be restricted to a preferred sign-in method for enhanced security.</li>
            <li>Two-factor authentication via SMS is available upon request for additional account protection.</li>
          </ul>

          <h4>2.4 Use and Disclosure of Personal Information (FOIP Sections 39-40)</h4>
          <ul className="tos-bullet-points">
            <li>Personal information is only used and disclosed for the purpose for which it was collected.</li>
            <li>Data is accessible only to authorized users, with others seeing only anonymized or aggregated data for statistical purposes where applicable.</li>
            <li>Any data exported from Edbotz services is anonymized to comply with FOIP requirements and ensure that individuals are not identifiable without consent.</li>
          </ul>

          <h4>2.5 Disclosure Harmful to Personal Privacy (FOIP Section 17)</h4>
          <ul className="tos-bullet-points">
            <li>Edbotz discourages users from entering sensitive personal information unless absolutely necessary for the service's functionality.</li>
            <li>Our platforms provide reminders to users, advising against the inclusion of unnecessary sensitive information.</li>
          </ul>
        </section>

        <section>
          <h3>3. Data Security Standards</h3>
          <p>Edbotz meets high data security standards to protect all user information:</p>
          <ul className="tos-bullet-points">
            <li>Encryption: All data is encrypted in transit and at rest using industry-standard encryption protocols.</li>
            <li>Secure Authentication: We use OAuth 2.0 protocol for Google and Microsoft Sign-In, which also incorporates OpenID Connect for secure authentication. Email verification with optional two-factor authentication via SMS is also available.</li>
            <li>Access Controls: Our real-time database and security rules ensure users only have access to their own data, identified by their unique user ID (UID).</li>
            <li>Domain Restrictions: School domains can be configured to use a specific sign-in method, enhancing organizational security.</li>
            <li>Regular Security Audits: We are committed to ongoing security improvements and undergo regular third-party security audits to validate our platforms' compliance with FOIP and ensure the security of user data.</li>
          </ul>
        </section>

        <section>
          <h3>4. Data Storage and Management</h3>
          <ul className="tos-bullet-points">
            <li>Data Storage: All data is stored securely on Google Firebase servers, which adhere to strict security and privacy standards.</li>
            <li>Data Access: Only authorized Edbotz personnel have access to the data, and this access is strictly limited to system maintenance and support purposes.</li>
            <li>Data Retention: Users can manage their data retention schedules directly through Edbotz platforms.</li>
            <li>Data Deletion: Upon request, we provide options for data anonymization or complete deletion in compliance with FOIP requirements.</li>
          </ul>
        </section>

        <section>
          <h3>5. User Responsibilities</h3>
          <ul className="tos-bullet-points">
            <li>Accurate Information: Users are responsible for ensuring the accuracy of the information they input into Edbotz services.</li>
            <li>Confidentiality: Users must maintain the confidentiality of their account credentials and not share access with unauthorized individuals.</li>
            <li>Appropriate Use: Users agree to use Edbotz services only for their intended purposes and in compliance with all applicable laws and regulations.</li>
            <li>Parental Consent: Users must ensure they have obtained necessary parental consent through their school's FOIP consent process before entering any student data into the system.</li>
          </ul>
        </section>

        <section>
          <h3>6. Student Data Protection</h3>
          <ul className="tos-bullet-points">
            <li>Edbotz strictly prohibits the storage of student data for any student whose parent or guardian has not provided consent through the school's FOIP consent process.</li>
            <li>Users are responsible for verifying parental consent before entering any student information into our system.</li>
            <li>We provide tools and reminders within our applications to help users comply with this requirement.</li>
          </ul>
        </section>

        <section>
          <h3>7. Changes to Terms of Service</h3>
          <p>
            We may update these Terms of Service from time to time. We will notify users of any significant changes via email or through the Edbotz platforms. Continued use of the Service after such notifications constitutes acceptance of the updated Terms.
          </p>
        </section>

        <section>
          <h3>8. Contact Information</h3>
          <p>
            If you have any questions about these Terms or our FOIP compliance practices, please contact us at kyle@edbotz.com.
          </p>
        </section>

        <p>
          By using any Edbotz service, you acknowledge that you have read and understood these Terms of Service and agree to be bound by them.
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;