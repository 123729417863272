import React, { useState } from "react";
import { 
  signInWithPopup, 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  sendPasswordResetEmail,
  sendEmailVerification,
  updateProfile
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth, googleProvider, microsoftProvider } from "../firebase";
import "./Login.css";
import { Link } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const [message, setMessage] = useState(null);
  const [name, setName] = useState(""); // New state for name input

  const signInWithProvider = (provider) => {
    setError(null);
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log("Signed in user:", result.user.displayName);
        navigate("/dashboard");
      })
      .catch((error) => {
        console.error("Sign-in error:", error.code, error.message);
        setError("Failed to sign in. Please try again.");
      });
  };

  const handleEmailVerification = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage(null);
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(userCredential.user);
      
      // Set display name for new user
      await updateProfile(userCredential.user, {
        displayName: name
      });
      
      setMessage("Verification email sent. Please check your inbox and verify your email before signing in.");
      await auth.signOut();
    } catch (error) {
      console.error("Email verification error:", error.code, error.message);
      switch (error.code) {
        case 'auth/email-already-in-use':
          setError("This email is already registered. Please sign in or use a different email.");
          break;
        case 'auth/invalid-email':
          setError("Invalid email address. Please check and try again.");
          break;
        case 'auth/weak-password':
          setError("Password is too weak. Please use a stronger password.");
          break;
        default:
          setError("Failed to create account. Please try again.");
      }
    }
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage(null);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      if (userCredential.user.emailVerified) {
        navigate("/dashboard");
      } else {
        setError("Please verify your email before signing in. Check your inbox for a verification link.");
        await auth.signOut();
      }
    } catch (error) {
      console.error("Sign-in error:", error.code, error.message);
      switch (error.code) {
        case 'auth/user-not-found':
          setError("No account found with this email. Please sign up first.");
          break;
        case 'auth/wrong-password':
          setError("Incorrect password. Please try again.");
          break;
        default:
          setError("Failed to sign in. Please try again.");
      }
    }
  };

  const handlePasswordReset = async () => {
    setError(null);
    setMessage(null);
    if (!email) {
      setError("Please enter your email address.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent. Please check your inbox.");
    } catch (error) {
      console.error("Password reset error:", error.code, error.message);
      setError("Failed to send password reset email. Please try again.");
    }
  };

  return (
    <div className="signin-container">
      <div className="signin-content">
        <div className="signin-left">
          <h1 className="company-name">edbotz</h1>
          <p className="company-slogan">Empowering Education Through Custom Technology</p>
          <div className="company-description">
            <p>Welcome to edbotz, your partner in educational innovation. We offer:</p>
            <ul>
              <li>Custom software solutions tailored for schools</li>
              <li>AI-powered teaching assistants for personalized tutoring</li>
              <li>Cutting-edge tools to enhance the learning experience</li>
            </ul>
            <p>Sign in to explore our suite of educational technology or create your account to get started with transforming your classroom today.</p>
          </div>
        </div>
        <div className="signin-right">
          <div className={`signin-box ${isSignUp ? 'signup-mode' : 'signin-mode'}`}>
            <h2 className="signin-title">{isSignUp ? "Sign Up" : "Sign In"}</h2>
            <form onSubmit={isSignUp ? handleEmailVerification : handleSignIn} className="email-password-form">
              {isSignUp && (
                <input
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              )}
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button type="submit" className="email-password-button">
                {isSignUp ? "Verify Email" : "Sign In"}
              </button>
            </form>
            {!isSignUp && (
              <p className="forgot-password" onClick={handlePasswordReset}>
                Forgot Password?
              </p>
            )}
            <p className="auth-switch" onClick={() => setIsSignUp(!isSignUp)}>
              {isSignUp ? "Already have an account? Sign In" : "Don't have an account? Sign Up"}
            </p>
            <div className="or-divider">
              <span>OR</span>
            </div>
            <button className="gsi-material-button" onClick={() => signInWithProvider(googleProvider)}>
              <div className="gsi-material-button-state"></div>
              <div className="gsi-material-button-content-wrapper">
                <div className="gsi-material-button-icon">
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ display: "block" }}>
                    <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                    <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                    <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                    <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                    <path fill="none" d="M0 0h48v48H0z"></path>
                  </svg>
                </div>
                <span className="gsi-material-button-contents">Sign in with Google</span>
                <span style={{ display: "none" }}>Sign in with Google</span>
              </div>
            </button>
            <button className="microsoft-login-button" onClick={() => signInWithProvider(microsoftProvider)}>
              <div className="microsoft-button-content">
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/2048px-Microsoft_logo.svg.png" alt="Microsoft logo" className="microsoft-logo" />
                <span>Sign in with Microsoft</span>
              </div>
            </button>
            {error && <p className="signin-error">{error}</p>}
            {message && <p className="signin-message">{message}</p>}
          </div>
        </div>
      </div>

      <div className="foip-link-container">
      <p>
      By signing in, you agree to our <Link to="/terms-of-service">Terms of Service</Link> and <Link to="/privacy-policy">Privacy Policy</Link>.
</p>
      </div>

      <footer className="signin-footer">
      <p>&copy; {new Date().getFullYear()} edbotz.com. All rights reserved.</p>

      </footer>
    </div>
  );
};

export default Login;
