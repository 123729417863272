// src/apps/AIAssistants/CreateAssistant.js
import React, { useState, useEffect } from 'react';
import { FaTimes, FaExpandAlt, FaLightbulb } from 'react-icons/fa';
import Select from 'react-select';
import TextareaAutosize from 'react-textarea-autosize';
import GlobalSettings from './GlobalSettings';
import './CreateAssistant.css';

function CreateAssistant({ onClose }) {
  const [assistantName, setAssistantName] = useState('');
  const [model, setModel] = useState(null);
  const [temperature, setTemperature] = useState(0.7);
  const [instructions, setInstructions] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);

  const modelOptions = [
    { value: GlobalSettings.openaiModels.openaiLowerModel, label: GlobalSettings.displayModels.displayLowerModel },
    { value: GlobalSettings.openaiModels.openaiHigherModel, label: GlobalSettings.displayModels.displayHigherModel }
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log({
      assistantName,
      model: model.value,
      temperature,
      instructions
    });
    onClose();
  };

  const showExamplePrompt = () => {
    const examplePrompt = `You are to take on the persona of William Shakespeare, the renowned playwright and poet. Your task is to teach students about the symbolism in Romeo and Juliet. Here are your instructions:

1. Language: Communicate in a mix of modern English and occasional Shakespearean phrases to make the content accessible yet authentic.

2. Knowledge: Demonstrate deep knowledge of Romeo and Juliet, its characters, plot, and historical context.

3. Symbolism Focus: Emphasize the symbolic elements in the play, such as:
   - The role of light and darkness
   - The symbolism of the balcony scene
   - The meaning behind the use of poison
   - The significance of the feuding families' names

4. Interactive Approach: Engage students by asking thought-provoking questions and encouraging them to find symbolic meanings in the text.

5. Historical Context: Provide insights into Elizabethan era beliefs and customs that influenced the symbolism in the play.

6. Creativity: Use creative methods to explain complex symbols, such as modern-day analogies or brief role-playing scenarios.

7. Patience and Encouragement: Be patient with students who may struggle with the material, offering encouragement and simplified explanations when needed.

Remember, your goal is to make the symbolism in Romeo and Juliet accessible and engaging for students, bringing Shakespeare's work to life through your unique persona.`;

    setInstructions(examplePrompt);
    setIsExpanded(true);
  };

  useEffect(() => {
    if (instructions.length > 500) {
      setIsExpanded(true);
    }
  }, [instructions]);

  return (
    <div className={`create-assistant-overlay ${isExpanded ? 'expanded' : ''}`}>
      <div className="create-assistant-modal">
        <button className="close-btn" onClick={onClose}>
          <FaTimes />
        </button>
        <h2>Create New AI Teaching Assistant</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="assistantName">Assistant Name<span className="required">*</span></label>
            <input
              type="text"
              id="assistantName"
              value={assistantName}
              onChange={(e) => setAssistantName(e.target.value)}
              required
              placeholder="Enter a name for your AI teaching assistant..."
              className="styled-input"
            />
          </div>

          <div className="form-group">
            <label htmlFor="model">Model<span className="required">*</span></label>
            <Select
              id="model"
              options={modelOptions}
              value={model}
              onChange={setModel}
              placeholder="Select a model..."
              className="react-select-container"
              classNamePrefix="react-select"
            />
            <p className="field-description">
              These are the latest models from OpenAI. The Standard model is suitable for most tasks, while the Advanced model offers enhanced capabilities for more complex assignments.
            </p>
          </div>

          <div className="form-group">
            <label htmlFor="temperature">Creativity Level<span className="required">*</span></label>
            <div className="slider-container">
              <input
                type="range"
                id="temperature"
                min="0"
                max="1"
                step="0.1"
                value={temperature}
                onChange={(e) => setTemperature(parseFloat(e.target.value))}
                className="styled-slider"
              />
              <span className="temperature-value">{temperature}</span>
            </div>
            <p className="field-description">
              This adjusts your assistant's creativity. Lower values (closer to 0) make the assistant more focused and consistent. Higher values (closer to 1) make it more creative and unpredictable.
            </p>
          </div>

          <div className="form-group">
            <div className="instructions-header">
              <label htmlFor="instructions">Instructions<span className="required">*</span></label>
              <div className="button-group">
                <button type="button" className="action-btn example-btn" onClick={showExamplePrompt}>
                  <FaLightbulb /> Example
                </button>
                <button type="button" className="action-btn expand-btn" onClick={() => setIsExpanded(!isExpanded)}>
                  <FaExpandAlt /> {isExpanded ? 'Collapse' : 'Expand'}
                </button>
              </div>
            </div>
            <TextareaAutosize
              id="instructions"
              value={instructions}
              onChange={(e) => setInstructions(e.target.value)}
              required
              placeholder="Provide instructions for your AI teaching assistant..."
              className={`styled-textarea ${isExpanded ? 'expanded' : ''}`}
              minRows={5}
              maxRows={isExpanded ? 20 : 10}
            />
            <p className="field-description">
              This is where you 'train' your assistant. Provide detailed instructions about their role, the subject they'll be assisting with, and how they should interact with students. Be specific about the assistant's personality, style, and any particular approaches or methods you want them to use.
            </p>
          </div>

          <button type="submit" className="submit-btn">Create AI Teaching Assistant</button>
        </form>
      </div>
    </div>
  );
}

export default CreateAssistant;