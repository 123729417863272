import React from 'react';
import { Link } from 'react-router-dom';

function AIVoice() {
  return (
    <div>
      <h2>AI Voice</h2>
      {/* Add your AI Voice app content here */}
      <Link to="/dashboard">Back to Dashboard</Link>
    </div>
  );
}

export default AIVoice;