import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, database } from "../../../firebase";
import { ref, push, remove, update, onValue } from "firebase/database";
import { FaPlus, FaMinus, FaTags, FaEdit } from "react-icons/fa";
import "./StudentManager.css";

const StudentManager = () => {
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [newStudent, setNewStudent] = useState({
    firstName: "",
    lastName: "",
    age: "",
    grade: "",
    teacher: "",
    categories: [""],
  });
  const [newCategoryInputs, setNewCategoryInputs] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const studentsRef = ref(database, `users/${user.uid}/SupportRoom/students`);
      const categoriesRef = ref(database, `users/${user.uid}/SupportRoom/categories`);

      onValue(studentsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const studentList = Object.entries(data).map(([key, value]) => ({
            id: key,
            ...value,
          }));
          setStudents(studentList);
        } else {
          setStudents([]);
        }
      });

      onValue(categoriesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const categoryList = Object.entries(data).map(([key, value]) => ({
            id: key,
            name: value,
          }));
          setCategories(categoryList);
        } else {
          setCategories([]);
        }
      });
    }
  }, []);

  const resetForm = () => {
    setNewStudent({
      firstName: "",
      lastName: "",
      age: "",
      grade: "",
      teacher: "",
      categories: [""],
    });
    setNewCategoryInputs([]);
    setSelectedStudent(null);
    setIsEditing(false);
    setShowForm(false);
    setError("");
  };

  const handleStudentAction = () => {
    const user = auth.currentUser;
    const studentsRef = ref(database, `users/${user.uid}/SupportRoom/students`);

    if (isEditing && selectedStudent) {
      update(
        ref(database, `users/${user.uid}/SupportRoom/students/${selectedStudent.id}`),
        {
          ...newStudent,
          categories: newStudent.categories.filter(
            (cat) => cat !== "" && cat !== "new"
          ),
        }
      )
        .then(() => {
          resetForm();
        })
        .catch((error) => {
          setError("Failed to update student. Please try again.");
          console.error("Error updating student: ", error);
        });
    } else {
      push(studentsRef, {
        ...newStudent,
        categories: newStudent.categories.filter(
          (cat) => cat !== "" && cat !== "new"
        ),
      })
        .then(() => {
          resetForm();
        })
        .catch((error) => {
          setError("Failed to add student. Please try again.");
          console.error("Error adding student: ", error);
        });
    }
  };

  const editStudent = (student) => {
    setSelectedStudent(student);
    setNewStudent({
      firstName: student.firstName,
      lastName: student.lastName,
      age: student.age,
      grade: student.grade,
      teacher: student.teacher,
      categories: student.categories || [""],
    });
    setIsEditing(true);
    setShowForm(true);
  };

  const deleteStudent = (studentId) => {
    if (
      window.confirm(
        "Are you sure you want to delete this student? This action cannot be undone."
      )
    ) {
      const user = auth.currentUser;
      const studentRef = ref(
        database,
        `users/${user.uid}/SupportRoom/students/${studentId}`
      );
      remove(studentRef).catch((error) => {
        setError("Failed to delete student. Please try again.");
        console.error("Error deleting student: ", error);
      });
    }
  };

  const handleCategoryChange = (index, value) => {
    const updatedCategories = [...newStudent.categories];
    updatedCategories[index] = value;
    setNewStudent({ ...newStudent, categories: updatedCategories });

    if (value === "new") {
      const updatedNewCategoryInputs = [...newCategoryInputs];
      updatedNewCategoryInputs[index] = "";
      setNewCategoryInputs(updatedNewCategoryInputs);
    } else {
      const updatedNewCategoryInputs = [...newCategoryInputs];
      updatedNewCategoryInputs[index] = undefined;
      setNewCategoryInputs(updatedNewCategoryInputs);
    }
  };

  const handleNewCategoryInputChange = (index, value) => {
    const updatedNewCategoryInputs = [...newCategoryInputs];
    updatedNewCategoryInputs[index] = value;
    setNewCategoryInputs(updatedNewCategoryInputs);
  };

  const createNewCategory = (index) => {
    const newCategoryName = newCategoryInputs[index];
    if (newCategoryName && newCategoryName.trim() !== "") {
      const user = auth.currentUser;
      const categoriesRef = ref(database, `users/${user.uid}/SupportRoom/categories`);
      const newCategoryRef = push(categoriesRef);
      const newCategoryId = newCategoryRef.key;

      update(categoriesRef, {
        [newCategoryId]: newCategoryName.trim(),
      })
        .then(() => {
          const updatedCategories = [...newStudent.categories];
          updatedCategories[index] = newCategoryId;
          setNewStudent({ ...newStudent, categories: updatedCategories });

          const updatedNewCategoryInputs = [...newCategoryInputs];
          updatedNewCategoryInputs[index] = undefined;
          setNewCategoryInputs(updatedNewCategoryInputs);
          setError("");
        })
        .catch((error) => {
          setError("Failed to create new category. Please try again.");
          console.error("Error creating new category: ", error);
        });
    }
  };

  const addCategoryField = () => {
    setNewStudent({
      ...newStudent,
      categories: [...newStudent.categories, ""],
    });
  };

  const removeCategoryField = (index) => {
    const updatedCategories = newStudent.categories.filter(
      (_, i) => i !== index
    );
    setNewStudent({ ...newStudent, categories: updatedCategories });

    const updatedNewCategoryInputs = [...newCategoryInputs];
    updatedNewCategoryInputs.splice(index, 1);
    setNewCategoryInputs(updatedNewCategoryInputs);
  };

  const handleBack = () => {
    navigate("/support-room-tracker/dashboard");
  };
  

  return (
    <div className="student-manager">
      <div className="student-manager-header">
        <button onClick={handleBack} className="back-button">
          &larr; Back to Dashboard
        </button>
        <h2 className="student-manager-title">Manage Students</h2>
      </div>

      {error && <div className="error-message">{error}</div>}

      <div className="student-list-container">
        <button
          onClick={() => {
            resetForm();
            setShowForm(true);
          }}
          className="add-student-button"
        >
          <FaPlus /> Add New Student
        </button>
        <ul className="student-list">
          {students.map((student) => (
            <li key={student.id} className="student-item">
              <span>
                {student.firstName} {student.lastName} - Grade: {student.grade},
                Teacher: {student.teacher}
                <br />
                Student Categories:{" "}
                {student.categories
                  ? student.categories
                      .map(
                        (catId) =>
                          categories.find((cat) => cat.id === catId)?.name
                      )
                      .join(", ")
                  : "None"}
              </span>
              <div className="student-actions">
                <button
                  onClick={() => editStudent(student)}
                  className="edit-student-button"
                >
                  <FaEdit />
                </button>
                <button
                  onClick={() => deleteStudent(student.id)}
                  className="delete-student-button"
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {showForm && (
        <div className="add-edit-student-form">
          <h3>{isEditing ? "Edit Student" : "Add New Student"}</h3>
          <div className="form-group">
            <label htmlFor="firstName">First Name:</label>
            <input
              id="firstName"
              type="text"
              value={newStudent.firstName}
              onChange={(e) =>
                setNewStudent({ ...newStudent, firstName: e.target.value })
              }
              className="student-input"
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name:</label>
            <input
              id="lastName"
              type="text"
              value={newStudent.lastName}
              onChange={(e) =>
                setNewStudent({ ...newStudent, lastName: e.target.value })
              }
              className="student-input"
            />
          </div>
          <div className="form-group">
            <label htmlFor="age">Age:</label>
            <input
              id="age"
              type="number"
              value={newStudent.age}
              onChange={(e) =>
                setNewStudent({ ...newStudent, age: e.target.value })
              }
              className="student-input"
            />
          </div>
          <div className="form-group">
            <label htmlFor="grade">Grade:</label>
            <input
              id="grade"
              type="text"
              value={newStudent.grade}
              onChange={(e) =>
                setNewStudent({ ...newStudent, grade: e.target.value })
              }
              className="student-input"
            />
          </div>
          <div className="form-group">
            <label htmlFor="teacher">Teacher:</label>
            <input
              id="teacher"
              type="text"
              value={newStudent.teacher}
              onChange={(e) =>
                setNewStudent({ ...newStudent, teacher: e.target.value })
              }
              className="student-input"
            />
          </div>

          <div className="category-section">
            <h3>
              Student Categories <FaTags />
            </h3>
            {newStudent.categories.map((category, index) => (
              <div key={index} className="category-field">
                <select
                  value={category}
                  onChange={(e) => handleCategoryChange(index, e.target.value)}
                  className="category-select"
                >
                  <option value="">Select a category</option>
                  {categories.map((cat) => (
                    <option key={cat.id} value={cat.id}>
                      {cat.name}
                    </option>
                  ))}
                  <option value="new">Create New Category</option>
                </select>
                {category === "new" && (
                  <div className="new-category-input-container">
                    <input
                      type="text"
                      value={newCategoryInputs[index] || ""}
                      onChange={(e) =>
                        handleNewCategoryInputChange(index, e.target.value)
                      }
                      placeholder="New Category Name"
                      className="new-category-input"
                    />
                    <button
                      onClick={() => createNewCategory(index)}
                      className="create-category-button"
                    >
                      Create
                    </button>
                  </div>
                )}
                {index === newStudent.categories.length - 1 ? (
                  <button
                    onClick={addCategoryField}
                    className="add-category-field"
                  >
                    <FaPlus />
                  </button>
                ) : (
                  <button
                    onClick={() => removeCategoryField(index)}
                    className="remove-category-field"
                  >
                    <FaMinus />
                  </button>
                )}
              </div>
            ))}
          </div>

          <button
            onClick={handleStudentAction}
            className="submit-student-button"
          >
            {isEditing ? "Update Student" : "Add Student"}
          </button>
          <button onClick={resetForm} className="cancel-button">
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};

export default StudentManager;
