import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { auth, database } from "../../../firebase";
import { ref, onValue, update, push } from "firebase/database";
import { FaPlus, FaMinus, FaArrowLeft } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./EditVisit.css";

const EditVisit = () => {
  const { visitId } = useParams();
  const navigate = useNavigate();
  const [visit, setVisit] = useState(null);
  const [students, setStudents] = useState([]);
  const [categories, setCategories] = useState([]);
  const [strategies, setStrategies] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedStrategies, setSelectedStrategies] = useState([]);
  const [visitDateTime, setVisitDateTime] = useState(new Date());
  const [studentLeftTime, setStudentLeftTime] = useState(null); // State for time student left
  const [newCategoryInputs, setNewCategoryInputs] = useState({});
  const [newStrategyInputs, setNewStrategyInputs] = useState({});
  const [duplicateCategories, setDuplicateCategories] = useState({});
  const [duplicateStrategies, setDuplicateStrategies] = useState({});

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const visitRef = ref(database, `users/${user.uid}/SupportRoom/visits/${visitId}`);
      const studentsRef = ref(database, `users/${user.uid}/SupportRoom/students`);
      const categoriesRef = ref(database, `users/${user.uid}/SupportRoom/visitCategories`);
      const strategiesRef = ref(database, `users/${user.uid}/SupportRoom/strategies`);
      const roomsRef = ref(database, `users/${user.uid}/SupportRoom/rooms`);

      onValue(visitRef, (snapshot) => {
        const visitData = snapshot.val();
        setVisit({ id: snapshot.key, ...visitData });
        setSelectedCategories(visitData.categories || []);
        setSelectedStrategies(visitData.strategies || []);
        setVisitDateTime(new Date(visitData.timestamp));
        if (visitData.studentLeftTimestamp) {
          setStudentLeftTime(new Date(visitData.studentLeftTimestamp));
        }
      });

      onValue(studentsRef, (snapshot) => {
        const studentsData = snapshot.val();
        setStudents(
          Object.entries(studentsData || {}).map(([id, data]) => ({
            id,
            ...data,
          }))
        );
      });

      onValue(categoriesRef, (snapshot) => {
        const categoriesData = snapshot.val();
        setCategories(
          Object.entries(categoriesData || {}).map(([id, name]) => ({
            id,
            name,
          }))
        );
      });

      onValue(strategiesRef, (snapshot) => {
        const strategiesData = snapshot.val();
        setStrategies(
          Object.entries(strategiesData || {}).map(([id, name]) => ({
            id,
            name,
          }))
        );
      });

      onValue(roomsRef, (snapshot) => {
        const roomsData = snapshot.val();
        setRooms(
          Object.entries(roomsData || {}).map(([id, name]) => ({ id, name }))
        );
      });
    }
  }, [visitId]);

  const normalizeString = (str) => {
    return str.replace(/\s+/g, '').toLowerCase(); // Remove all spaces and convert to lowercase
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVisit((prev) => ({ ...prev, [name]: value }));
  };

  const handleCategoryChange = (index, value) => {
    const updatedCategories = [...selectedCategories];
    updatedCategories[index] = value;
    setSelectedCategories(updatedCategories);

    if (value === "new") {
      setNewCategoryInputs(prev => ({ ...prev, [index]: "" }));
    } else {
      setNewCategoryInputs(prev => {
        const updated = { ...prev };
        delete updated[index];
        return updated;
      });
      setDuplicateCategories(prev => {
        const updated = { ...prev };
        delete updated[index];
        return updated;
      });
    }
  };

  const handleStrategyChange = (index, value) => {
    const updatedStrategies = [...selectedStrategies];
    updatedStrategies[index] = value;
    setSelectedStrategies(updatedStrategies);

    if (value === "new") {
      setNewStrategyInputs(prev => ({ ...prev, [index]: "" }));
    } else {
      setNewStrategyInputs(prev => {
        const updated = { ...prev };
        delete updated[index];
        return updated;
      });
      setDuplicateStrategies(prev => {
        const updated = { ...prev };
        delete updated[index];
        return updated;
      });
    }
  };

  const handleNewCategoryInputChange = (index, value) => {
    setNewCategoryInputs(prev => ({ ...prev, [index]: value }));

    const isDuplicate = categories.some(
      category => normalizeString(category.name) === normalizeString(value)
    ) || Object.values(newCategoryInputs).some(
      (input, i) => i !== index && normalizeString(input) === normalizeString(value)
    );

    setDuplicateCategories(prev => ({
      ...prev,
      [index]: isDuplicate,
    }));
  };

  const handleNewStrategyInputChange = (index, value) => {
    setNewStrategyInputs(prev => ({ ...prev, [index]: value }));

    const isDuplicate = strategies.some(
      strategy => normalizeString(strategy.name) === normalizeString(value)
    ) || Object.values(newStrategyInputs).some(
      (input, i) => i !== index && normalizeString(input) === normalizeString(value)
    );

    setDuplicateStrategies(prev => ({
      ...prev,
      [index]: isDuplicate,
    }));
  };

  const addCategoryField = () => {
    setSelectedCategories([...selectedCategories, ""]);
  };

  const addStrategyField = () => {
    setSelectedStrategies([...selectedStrategies, ""]);
  };

  const removeCategoryField = (index) => {
    const updatedCategories = selectedCategories.filter((_, i) => i !== index);
    setSelectedCategories(updatedCategories);
    setNewCategoryInputs(prev => {
      const updated = { ...prev };
      delete updated[index];
      return updated;
    });
    setDuplicateCategories(prev => {
      const updated = { ...prev };
      delete updated[index];
      return updated;
    });
  };

  const removeStrategyField = (index) => {
    const updatedStrategies = selectedStrategies.filter((_, i) => i !== index);
    setSelectedStrategies(updatedStrategies);
    setNewStrategyInputs(prev => {
      const updated = { ...prev };
      delete updated[index];
      return updated;
    });
    setDuplicateStrategies(prev => {
      const updated = { ...prev };
      delete updated[index];
      return updated;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser;
    if (user) {
      const visitRef = ref(database, `users/${user.uid}/SupportRoom/visits/${visitId}`);
      const categoriesRef = ref(database, `users/${user.uid}/SupportRoom/visitCategories`);
      const strategiesRef = ref(database, `users/${user.uid}/SupportRoom/strategies`);

      const newCategories = [];
      const newStrategies = [];

      // Handle new categories
      for (const [index, categoryId] of selectedCategories.entries()) {
        if (categoryId === "new") {
          const newCategoryName = newCategoryInputs[index];
          if (newCategoryName) {
            const newCategoryRef = await push(categoriesRef, newCategoryName);
            newCategories.push(newCategoryRef.key);
          }
        } else if (categoryId) {
          newCategories.push(categoryId);
        }
      }

      // Handle new strategies
      for (const [index, strategyId] of selectedStrategies.entries()) {
        if (strategyId === "new") {
          const newStrategyName = newStrategyInputs[index];
          if (newStrategyName) {
            const newStrategyRef = await push(strategiesRef, newStrategyName);
            newStrategies.push(newStrategyRef.key);
          }
        } else if (strategyId) {
          newStrategies.push(strategyId);
        }
      }

      const updatedVisit = {
        ...visit,
        categories: newCategories,
        strategies: newStrategies,
        timestamp: visitDateTime.getTime(),
        studentLeftTimestamp: studentLeftTime ? studentLeftTime.getTime() : null, // Add the student left timestamp
      };

      await update(visitRef, updatedVisit);
      navigate("/support-room-tracker/dashboard");
    }
  };

  const isSubmitDisabled = Object.values(duplicateCategories).some(Boolean) || 
                           Object.values(duplicateStrategies).some(Boolean);

  if (!visit) return <div>Loading...</div>;

  return (
    <div className="edit-visit">
      <div className="form-header">
        <button onClick={() => navigate("/support-room-tracker/dashboard")} className="back-button">
          <FaArrowLeft /> Back to Dashboard
        </button>
        <h2>Edit Visit</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Student:</label>
          <select
            name="studentId"
            value={visit.studentId}
            onChange={handleChange}
          >
            {students.map((student) => (
              <option key={student.id} value={student.id}>
                {student.firstName} {student.lastName}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Date and Time:</label>
          <DatePicker
            selected={visitDateTime}
            onChange={(date) => setVisitDateTime(date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="MMMM d, yyyy h:mm aa"
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Time Student Left:</label> {/* Updated title */}
          <DatePicker
            selected={studentLeftTime}
            onChange={(date) => setStudentLeftTime(date)}
            showTimeSelect
            showTimeSelectOnly
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="h:mm aa"
            className="form-control"
            placeholderText="Select time"
          />
        </div>
        <div className="form-group">
          <label>Categories:</label>
          {selectedCategories.map((categoryId, index) => (
            <div key={index} className="category-field">
              <select
                value={categoryId}
                onChange={(e) => handleCategoryChange(index, e.target.value)}
              >
                <option value="">Select a category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
                <option value="new">Add new category</option>
              </select>
              {categoryId === "new" && (
                <>
                  <input
                    type="text"
                    value={newCategoryInputs[index] || ""}
                    onChange={(e) => handleNewCategoryInputChange(index, e.target.value)}
                    placeholder="Enter new category name"
                  />
                  {duplicateCategories[index] && (
                    <span className="error-message">This category already exists.</span>
                  )}
                </>
              )}
              {index === selectedCategories.length - 1 ? (
                <button
                  type="button"
                  onClick={addCategoryField}
                  className="add-category-field"
                >
                  <FaPlus />
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => removeCategoryField(index)}
                  className="remove-category-field"
                >
                  <FaMinus />
                </button>
              )}
            </div>
          ))}
          {selectedCategories.length === 0 && (
            <button
              type="button"
              onClick={addCategoryField}
              className="add-category-field"
            >
              <FaPlus /> Add Category
            </button>
          )}
        </div>
        <div className="form-group">
          <label>Strategies:</label>
          {selectedStrategies.map((strategyId, index) => (
            <div key={index} className="category-field">
              <select
                value={strategyId}
                onChange={(e) => handleStrategyChange(index, e.target.value)}
              >
                <option value="">Select a strategy</option>
                {strategies.map((strategy) => (
                  <option key={strategy.id} value={strategy.id}>
                    {strategy.name}
                  </option>
                ))}
                <option value="new">Add new strategy</option>
              </select>
              {strategyId === "new" && (
                <>
                  <input
                    type="text"
                    value={newStrategyInputs[index] || ""}
                    onChange={(e) => handleNewStrategyInputChange(index, e.target.value)}
                    placeholder="Enter new strategy name"
                  />
                  {duplicateStrategies[index] && (
                    <span className="error-message">This strategy already exists.</span>
                  )}
                </>
              )}
              {index === selectedStrategies.length - 1 ? (
                <button
                  type="button"
                  onClick={addStrategyField}
                  className="add-category-field"
                >
                  <FaPlus />
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => removeStrategyField(index)}
                  className="remove-category-field"
                >
                  <FaMinus />
                </button>
              )}
            </div>
          ))}
          {selectedStrategies.length === 0 && (
            <button
              type="button"
              onClick={addStrategyField}
              className="add-category-field"
            >
              <FaPlus /> Add Strategy
            </button>
          )}
        </div>
        <div className="form-group">
          <label>Room:</label>
          <select name="roomId" value={visit.roomId} onChange={handleChange}>
            {rooms.map((room) => (
              <option key={room.id} value={room.id}>
                {room.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Notes:</label>
          <textarea
            name="notes"
            value={visit.notes || ""}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>
            <input
              type="checkbox"
              name="isFlagged"
              checked={visit.isFlagged}
              onChange={(e) =>
                setVisit((prev) => ({ ...prev, isFlagged: e.target.checked }))
              }
            />
            Flag this visit
          </label>
        </div>
        <button type="submit" className="save-button" disabled={isSubmitDisabled}>
          Save Changes
        </button>
        {isSubmitDisabled && (
          <p className="error-message">Please resolve duplicate categories or strategies before saving.</p>
        )}
      </form>
    </div>
  );
};

export default EditVisit;
