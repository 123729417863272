import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, database } from "../../../firebase";
import { ref, push, remove, onValue } from "firebase/database";
import { FaInfoCircle, FaChevronDown, FaChevronUp } from "react-icons/fa";
import "./StrategiesManager.css";

const StrategiesManager = () => {
  const navigate = useNavigate();
  const [strategies, setStrategies] = useState([]);
  const [newStrategy, setNewStrategy] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const strategiesRef = ref(database, `users/${user.uid}/SupportRoom/strategies`);
      onValue(strategiesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const strategyList = Object.entries(data).map(([key, value]) => ({
            id: key,
            name: value,
          }));
          setStrategies(strategyList);
        } else {
          setStrategies([]);
        }
      });
    }
  }, []);

  const normalizeString = (str) => {
    return str.replace(/\s+/g, '').toLowerCase(); // Remove all spaces and convert to lowercase
  };

  const handleStrategyInputChange = (e) => {
    const value = e.target.value;
    setNewStrategy(value);

    // Check for duplicates by normalizing the input and existing strategies
    const normalizedValue = normalizeString(value);

    const duplicate = strategies.some(
      (strategy) => normalizeString(strategy.name) === normalizedValue
    );
    setIsDuplicate(duplicate);
  };

  const addStrategy = () => {
    if (newStrategy.trim() !== "" && !isDuplicate) {
      const user = auth.currentUser;
      const strategiesRef = ref(database, `users/${user.uid}/SupportRoom/strategies`);
      push(strategiesRef, newStrategy.trim());
      setNewStrategy("");
    }
  };

  const deleteStrategy = (strategyId) => {
    const user = auth.currentUser;
    const strategyRef = ref(
      database,
      `users/${user.uid}/SupportRoom/strategies/${strategyId}`
    );
    remove(strategyRef);
  };

  const handleBack = () => {
    navigate("/support-room-tracker/dashboard");
  };
  

  return (
    <div className="visit-categories-manager">
      <div className="visit-categories-manager-header">
        <button onClick={handleBack} className="back-button">
          &larr; Back to Dashboard
        </button>
        <h2 className="visit-categories-manager-title">
          Manage Support Strategies
        </h2>
      </div>

      <div className="info-section">
        <button className="info-toggle" onClick={() => setShowInfo(!showInfo)}>
          <FaInfoCircle /> Information about Strategies
          {showInfo ? <FaChevronUp /> : <FaChevronDown />}
        </button>
        {showInfo && (
          <div className="info-content">
            <p>
              Support strategies are specific approaches or techniques used to assist students
              during their visits to the support room. These strategies help in providing
              consistent and effective support to students.
            </p>
            <p>
              Examples of support strategies might include: "Deep Breathing Exercises",
              "One-on-One Tutoring", "Positive Reinforcement", "Goal Setting", etc.
            </p>
            <p>
              Note: These strategies can be associated with visits to track which
              approaches are being used and their effectiveness over time.
            </p>
          </div>
        )}
      </div>

      <div className="add-category-form">
        <input
          type="text"
          value={newStrategy}
          onChange={handleStrategyInputChange}
          placeholder="New Support Strategy"
          className="category-input"
        />
        <button 
          onClick={addStrategy} 
          className="add-category-button"
          disabled={isDuplicate || newStrategy.trim() === ""}
        >
          Add Strategy
        </button>
      </div>

      {isDuplicate && (
        <p className="error-message">This strategy already exists.</p>
      )}

      <ul className="category-list">
        {strategies.map((strategy) => (
          <li key={strategy.id} className="category-item">
            <span>{strategy.name}</span>
            <button
              onClick={() => deleteStrategy(strategy.id)}
              className="delete-category-button"
            >
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default StrategiesManager;
