// GlobalSettings.js
const GlobalSettings = {
    openaiModels: {
        openaiLowerModel: 'gpt-4o mini',
        openaiHigherModel: 'gpt-4o'
    },
    displayModels: {
        displayLowerModel: 'Standard AI Model (GPT 4o mini)',
        displayHigherModel: 'Advanced AI Model (GPT 4o)'
    },
    pricing: {
        // Define your pricing structure here
    },
    // Other global settings can be added here
};

export default GlobalSettings;