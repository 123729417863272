import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { format, addHours } from "date-fns";
import {
  FaFlag,
  FaChevronDown,
  FaChevronUp,
  FaEdit,
  FaExpandAlt,    // Import this icon
  FaCompressAlt   // Import this icon as well
} from "react-icons/fa";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./AllVisits.css";
import { ref, remove } from "firebase/database";
import { auth, database } from "../../../firebase";


const ConfirmationModal = ({ isOpen, onClose, onConfirm, message }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p>{message}</p>
        <div className="modal-actions">
          <button onClick={onClose}>Cancel</button>
          <button onClick={onConfirm}>Confirm</button>
        </div>
      </div>
    </div>
  );
};

const AllVisits = ({ filters = {}, visits, onVisitsUpdated }) => {
  const [expandedVisit, setExpandedVisit] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [selectedVisits, setSelectedVisits] = useState([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const location = useLocation();
  const fromDashboard = location.state?.fromDashboard ?? true;

  const {
    selectedStudents = [],
    selectedRooms = [],
    selectedVisitCategories = [],
    selectedStudentCategories = [],
    selectedStrategies = [],
    students = {},
    rooms = {},
    visitCategories = {},
    studentCategories = {},
    strategies = {},
  } = filters;

  // Sort visits by timestamp in descending order
  const filteredVisits = visits
    .filter((visit) => {
      const student = students[visit.studentId];

      if (selectedStudents.length > 0 && !selectedStudents.some((s) => s.value === visit.studentId)) {
        return false;
      }

      if (selectedRooms.length > 0) {
        if (selectedRooms.some((r) => r.value === "not_set")) {
          if (visit.roomId && !selectedRooms.some((r) => r.value === visit.roomId)) {
            return false;
          }
        } else if (!selectedRooms.some((r) => r.value === visit.roomId)) {
          return false;
        }
      }

      if (selectedVisitCategories.length > 0) {
        if (selectedVisitCategories.some((vc) => vc.value === "not_set")) {
          if (
            visit.categories &&
            visit.categories.length > 0 &&
            !visit.categories.some((cat) =>
              selectedVisitCategories.some((sc) => sc.value === cat)
            )
          ) {
            return false;
          }
        } else if (
          !visit.categories ||
          !visit.categories.some((cat) =>
            selectedVisitCategories.some((sc) => sc.value === cat)
          )
        ) {
          return false;
        }
      }

      if (selectedStudentCategories.length > 0) {
        if (selectedStudentCategories.some((sc) => sc.value === "not_set")) {
          if (
            student &&
            student.categories &&
            student.categories.length > 0 &&
            !student.categories.some((cat) =>
              selectedStudentCategories.some((sc) => sc.value === cat)
            )
          ) {
            return false;
          }
        } else if (
          !student ||
          !student.categories ||
          !student.categories.some((cat) =>
            selectedStudentCategories.some((sc) => sc.value === cat)
          )
        ) {
          return false;
        }
      }

      if (selectedStrategies.length > 0) {
        if (!visit.strategies || !visit.strategies.some((str) => selectedStrategies.some((fs) => fs.value === str))) {
          return false;
        }
      }

      return true;
    })
    .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)); // Sort by timestamp descending

  const getInitials = (name) => {
    return name
      .split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase();
  };

  const getCategoryNames = (categoryIds, categoryType) => {
    const categories = categoryType === "visit" ? visitCategories : studentCategories;
    return categoryIds && categoryIds.length > 0
      ? categoryIds.map((id) => categories[id] || "Unknown").join(", ")
      : "Not Set";
  };

  const getStrategyNames = (strategyIds) => {
    if (!strategyIds || strategyIds.length === 0) {
      return "Not Set";
    }

    const strategyNames = strategyIds.map((id) => {
      if (typeof strategies[id] === "string") {
        return strategies[id];
      } else if (strategies[id] && strategies[id].name) {
        return strategies[id].name;
      } else {
        return "Unknown Strategy";
      }
    });

    return strategyNames.join(", ") || "Not Set";
  };

  const getAvatarColor = (initials) => {
    const colors = [
      "#1abc9c",
      "#2ecc71",
      "#3498db",
      "#9b59b6",
      "#34495e",
      "#16a085",
      "#27ae60",
      "#2980b9",
      "#8e44ad",
      "#2c3e50",
      "#f1c40f",
      "#e67e22",
      "#e74c3c",
      "#ecf0f1",
      "#95a5a6",
      "#f39c12",
      "#d35400",
      "#c0392b",
      "#bdc3c7",
      "#7f8c8d",
    ];
    const charCode = initials.charCodeAt(0) + (initials.charCodeAt(1) || 0);
    return colors[charCode % colors.length];
  };

  const toggleExpand = (visitId) => {
    if (!isDeleteMode) {
      setExpandedVisit(expandedVisit === visitId ? null : visitId);
    }
  };

  const toggleExpandAll = () => {
    setIsExpanded(!isExpanded);
  };

  const handleTimeChange = async (visitId, newTime) => {
    console.log("Time change for visit", visitId, "to", newTime);
  };

  const toggleDeleteMode = () => {
    setIsDeleteMode(!isDeleteMode);
    setSelectedVisits([]);
  };

  const toggleVisitSelection = (visitId) => {
    setSelectedVisits((prevSelected) =>
      prevSelected.includes(visitId)
        ? prevSelected.filter((id) => id !== visitId)
        : [...prevSelected, visitId]
    );
  };

  const handleDeleteSelected = () => {
    setIsConfirmModalOpen(true);
  };

  const confirmDelete = async () => {
    const user = auth.currentUser;
    if (!user) {
      alert("User not authenticated. Please sign in again.");
      return;
    }

    const deleteVisit = async (visitId) => {
      try {
        const visitRef = ref(database, `users/${user.uid}/SupportRoom/visits/${visitId}`);
        await remove(visitRef);
      } catch (error) {
        console.error(`Failed to delete visit with ID ${visitId}:`, error);
        throw error;
      }
    };

    try {
      await Promise.all(selectedVisits.map(deleteVisit));
      setSelectedVisits([]);
      setIsDeleteMode(false);
      setIsConfirmModalOpen(false);

      if (typeof onVisitsUpdated === "function") {
        onVisitsUpdated();
      }
    } catch (error) {
      console.error("Error during the deletion process:", error);
      alert("Some visits may not have been deleted. Please refresh and try again if necessary.");
    }
  };

  const handleSelectAll = () => {
    if (selectedVisits.length === filteredVisits.length) {
      setSelectedVisits([]); // Deselect all
    } else {
      setSelectedVisits(filteredVisits.map((visit) => visit.id)); // Select all
    }
  };

  return (
    <div className="all-visits">
      {!fromDashboard && (
        <Link to="/dashboard" className="back-link">
          Back to Dashboard
        </Link>
      )}
  
      <div className="visits-actions">
        <button onClick={toggleExpandAll} className="expand-all-button">
          {isExpanded ? <FaCompressAlt /> : <FaExpandAlt />}
          {isExpanded ? "Collapse All" : "Expand All"}
        </button>
        <button onClick={toggleDeleteMode} className="delete-mode-button">
          {isDeleteMode ? "Cancel Select" : "Select"}
        </button>
        {isDeleteMode && (
          <>
            <button
              onClick={handleSelectAll}
              className="select-all-button"
            >
              {selectedVisits.length === filteredVisits.length ? "Deselect All" : "Select All"}
            </button>
            <button
              onClick={handleDeleteSelected}
              className="delete-selected-button"
              disabled={selectedVisits.length === 0}
            >
              Delete Selected ({selectedVisits.length})
            </button>
          </>
        )}
      </div>
  
      {filteredVisits.length > 0 ? (
        <ul className="visit-list">
          {filteredVisits.map((visit) => {
            const student = students[visit.studentId];
            let studentName = "Unknown Student";
            if (student) {
              const firstName = student.firstName || "";
              const lastName = student.lastName || "";
              studentName = `${firstName} ${lastName}`.trim() || "Unknown Student";
            }
  
            const initials = getInitials(studentName);
            const avatarColor = getAvatarColor(initials);
            const isVisitExpanded = isExpanded || expandedVisit === visit.id;
  
            const visitTime = new Date(visit.timestamp);
            const maxTime = addHours(visitTime, 8);
  
            return (
              <li
                key={visit.id}
                className={`visit-item ${isVisitExpanded ? "expanded" : ""} ${isDeleteMode ? "delete-mode" : ""}`}
              >
                {isDeleteMode && (
                  <input
                    type="checkbox"
                    checked={selectedVisits.includes(visit.id)}
                    onChange={() => toggleVisitSelection(visit.id)}
                    onClick={(e) => e.stopPropagation()}
                    className="visit-checkbox"
                  />
                )}
                {visit.isFlagged && <FaFlag className="flag-icon" />}
                <div
                  className="visit-avatar"
                  style={{ backgroundColor: avatarColor }}
                >
                  {initials}
                </div>
                <div className="visit-details">
                  <div className="primary-info-row">
                    <span className="visit-student-name">{studentName}</span>
                    <span className="visit-time">{format(visitTime, "MMM d, yyyy h:mm a")}</span>
                    <div className="time-out-container">
                      <span className="time-out-label">Time Out:</span>
                      <DatePicker
                        selected={visit.studentLeftTimestamp ? new Date(visit.studentLeftTimestamp) : null}
                        onChange={(date) => handleTimeChange(visit.id, date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeFormat="hh:mm aa"
                        timeIntervals={15}
                        dateFormat="h:mm aa"
                        placeholderText="Set time"
                        className="time-out-picker"
                        minTime={visitTime}
                        maxTime={maxTime}
                        onClick={(e) => e.stopPropagation()} // Prevent expansion on time input click
                      />
                    </div>
                    <Link
  to={`/support-room-tracker/edit-visit/${visit.id}`}
  className="edit-link"
  onClick={(e) => e.stopPropagation()}
  title="Edit Visit"
>
  <FaEdit />
</Link>
                    <div className="expand-icon" onClick={() => toggleExpand(visit.id)}>
                      {isVisitExpanded ? <FaChevronUp /> : <FaChevronDown />}
                    </div>
                  </div>
                  {isVisitExpanded && !isDeleteMode && (
                    <div className="expanded-container">
                      <div className="visit-expanded">
                        <strong>Room:</strong> {rooms[visit.roomId] || "Not Set"}
                        <br />
                        <strong>Visit Categories:</strong> {getCategoryNames(visit.categories, "visit")}
                        <br />
                        <strong>Student Categories:</strong> {getCategoryNames(student ? student.categories : [], "student")}
                        <br />
                        <strong>Strategies:</strong> {getStrategyNames(visit.strategies)}
                        <br />
                        <strong>Notes:</strong> {visit.notes || "No notes"}
                      </div>
                    </div>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <p>No visits found for the selected filters.</p>
      )}
  
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={confirmDelete}
        message={`Are you sure you want to delete ${selectedVisits.length} visit${selectedVisits.length !== 1 ? 's' : ''}?`}
      />
    </div>
  );
  
};

export default AllVisits;
