import React from 'react';
import './SupportFooter.css';

const SupportFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="dashboard-footer">
      <p>&copy; {currentYear} edbotz.com. All rights reserved.</p>
    </footer>
  );
};

export default SupportFooter;