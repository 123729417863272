// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import AssistantsDashboard from './apps/AIAssistants/AssistantsDashboard';
import AIVoice from './apps/AIVoice/AIVoice';

// Support Room Tracker Imports
import SupportRoomDashboard from './apps/SupportRoomTracker/src/Dashboard';
import RoomManager from './apps/SupportRoomTracker/src/RoomManager';
import StudentManager from './apps/SupportRoomTracker/src/StudentManager';
import CustomCategoriesManager from './apps/SupportRoomTracker/src/CustomCategoriesManager';
import VisitCategoriesManager from './apps/SupportRoomTracker/src/VisitCategoriesManager';
import VisitForm from './apps/SupportRoomTracker/src/VisitForm';
import AllVisits from './apps/SupportRoomTracker/src/AllVisits';
import EditVisit from './apps/SupportRoomTracker/src/EditVisit';
import StrategiesManager from './apps/SupportRoomTracker/src/StrategiesManager';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy'; // New import for Privacy Policy

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/login" element={user ? <Navigate to="/dashboard" /> : <Login />} />
        <Route path="/dashboard" element={user ? <Dashboard /> : <Navigate to="/login" />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* New route for Privacy Policy */}
        <Route path="/" element={<Navigate to={user ? "/dashboard" : "/login"} />} />
        
        {/* Routes for AI Assistants and AI Voice */}
        <Route path="/ai-assistants" element={user ? <AssistantsDashboard /> : <Navigate to="/login" />} />
        <Route path="/ai-voice" element={user ? <AIVoice /> : <Navigate to="/login" />} />
        
        {/* Support Room Tracker Routes */}
        <Route path="/support-room-tracker" element={<Navigate to="/support-room-tracker/dashboard" />} />
        <Route path="/support-room-tracker/dashboard" element={user ? <SupportRoomDashboard /> : <Navigate to="/login" />} />
        <Route path="/support-room-tracker/room-manager" element={user ? <RoomManager /> : <Navigate to="/login" />} />
        <Route path="/support-room-tracker/student-manager" element={user ? <StudentManager /> : <Navigate to="/login" />} />
        <Route path="/support-room-tracker/all-visits" element={user ? <AllVisits /> : <Navigate to="/login" />} />
        <Route path="/support-room-tracker/edit-visit/:visitId" element={user ? <EditVisit /> : <Navigate to="/login" />} />
        <Route path="/support-room-tracker/category-manager" element={user ? <CustomCategoriesManager /> : <Navigate to="/login" />} />
        <Route path="/support-room-tracker/new-visit" element={user ? <VisitForm /> : <Navigate to="/login" />} />
        <Route path="/support-room-tracker/visit-category-manager" element={user ? <VisitCategoriesManager /> : <Navigate to="/login" />} />
        <Route path="/support-room-tracker/strategies-manager" element={user ? <StrategiesManager /> : <Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;