import React from 'react';
import { useNavigate } from 'react-router-dom';
import './TermsOfService.css';

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <div className="edbotz-terms-of-service">
      <div className="tos-container">
        <button className="tos-back-button" onClick={() => navigate('/login')}>
          Back to Login
        </button>
        <h2 className="tos-title">Edbotz Privacy Policy</h2>
        <p className="tos-date">Last Updated: September 10, 2024</p>

        <p>
          At Edbotz, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your data when you use our services.
        </p>

        <section>
          <h3>1. Types of Data Collected</h3>
          <p>We collect the following types of information:</p>
          <ul className="tos-bullet-points">
            <li>Personal Information: Such as name, email address, and other identifiers necessary for using our services.</li>
            <li>Usage Data: Information on how you interact with our services.</li>
            <li>User-Generated Content: Any data you input into our systems as part of using our services.</li>
            <li>Student Data: Information related to students, which is subject to additional protections and consent requirements.</li>
            <li>Authentication Data: Information required for secure sign-in, which varies based on the chosen authentication method.</li>
          </ul>
        </section>

        <section>
          <h3>2. How We Collect Data</h3>
          <ul className="tos-bullet-points">
            <li>Directly from you: When you create an account, use our services, or communicate with us.</li>
            <li>Automatically: Through your interaction with our services, we may collect usage data.</li>
            <li>Through Authentication: When you sign in using Google, Microsoft, or email verification.</li>
          </ul>
        </section>

        <section>
          <h3>3. How We Use Your Data</h3>
          <p>We use your data to:</p>
          <ul className="tos-bullet-points">
            <li>Provide and maintain our services</li>
            <li>Improve and personalize user experience</li>
            <li>Communicate with you about our services</li>
            <li>Comply with legal obligations</li>
            <li>Authenticate and secure your account</li>
          </ul>
        </section>

        <section>
          <h3>4. Student Data and Parental Consent</h3>
          <ul className="tos-bullet-points">
            <li>We do not permit the storage of student data for any student whose parent or guardian has not provided consent through the school's FOIP consent process.</li>
            <li>Users are responsible for ensuring they have obtained necessary parental consent before entering any student information into our system.</li>
            <li>We provide tools and reminders within our applications to assist users in complying with this requirement.</li>
          </ul>
        </section>

        <section>
          <h3>5. Data Sharing and Disclosure</h3>
          <p>We do not sell your personal information. We may share data:</p>
          <ul className="tos-bullet-points">
            <li>With your consent</li>
            <li>To comply with legal obligations</li>
            <li>With service providers who assist in our operations, under strict confidentiality agreements</li>
          </ul>
        </section>

        <section>
          <h3>6. Data Storage and Security</h3>
          <ul className="tos-bullet-points">
            <li>All data is stored on secure Google Firebase servers.</li>
            <li>We implement industry-standard security measures, including encryption and access controls.</li>
            <li>For Google and Microsoft Sign-In, we use the OAuth 2.0 protocol, which also incorporates OpenID Connect, ensuring a secure and standardized authentication process. Email verification with optional two-factor authentication via SMS is also available.</li>
            <li>School domains can be restricted to a preferred sign-in method for enhanced security.</li>
            <li>We regularly conduct security audits to ensure the integrity of our systems.</li>
          </ul>
        </section>

        <section>
          <h3>7. Your Rights</h3>
          <p>You have the right to:</p>
          <ul className="tos-bullet-points">
            <li>Access your personal information</li>
            <li>Correct inaccurate or incomplete information</li>
            <li>Request deletion of your data</li>
            <li>Object to or restrict certain data processing</li>
          </ul>
          <p>For student data, these rights are typically exercised through the school or educational institution.</p>
        </section>

        <section>
          <h3>8. Data Retention</h3>
          <p>
            We retain personal information only for as long as necessary to provide our services and comply with legal obligations. Users can manage their data retention preferences within our platforms.
          </p>
        </section>

        <section>
          <h3>9. Protection of Student Privacy</h3>
          <p>We take additional measures to protect student privacy:</p>
          <ul className="tos-bullet-points">
            <li>We collect only the minimum amount of student information necessary for the functioning of our services.</li>
            <li>Access to student data is strictly limited to authorized users within the educational institution.</li>
            <li>We provide educational institutions with tools to manage and delete student data in accordance with their policies and legal requirements.</li>
          </ul>
        </section>

        <section>
          <h3>10. Authentication and Account Security</h3>
          <ul className="tos-bullet-points">
            <li>We offer multiple secure authentication methods to protect user accounts.</li>
            <li>Users can choose between Google Sign-In and Microsoft Sign-In, both of which use OAuth 2.0 and OpenID Connect protocols, or email verification for account access.</li>
            <li>Two-factor authentication via SMS is available upon request for additional security.</li>
            <li>School administrators can restrict their domain to a preferred sign-in method, enhancing organizational security.</li>
          </ul>
        </section>

        <section>
          <h3>11. Changes to This Privacy Policy</h3>
          <p>
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.
          </p>
        </section>

        <section>
          <h3>12. Contact Us</h3>
          <p>
            If you have any questions about this Privacy Policy, please contact us at kyle@edbotz.com.
          </p>
        </section>


        <p>
          By using Edbotz services, you agree to the collection and use of information in accordance with this Privacy Policy.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;